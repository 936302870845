var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    !_vm.loadingAvaza && !_vm.mapping
      ? _c(
          "span",
          [
            _vm.avazaTaskId
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      text: "",
                      "x-small": "",
                      color: "green accent-4",
                      href:
                        "https://gtegroup.avaza.com/project/view/" +
                        _vm.avazaProjectId +
                        "#!tab=task-pane&groupby=ProjectSection&view=vertical&task=" +
                        _vm.avazaTaskId +
                        "&fileview=grid",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      "\n            Task " +
                        _vm._s(_vm.avazaTaskId) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.costUnitIssues
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-chip",
                                _vm._g(
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      small: "",
                                      icon: "",
                                      color: "red",
                                      "text-color": "white",
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n                    Issues\n                    "
                                  ),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v(
                                      "\n                        mdi-alert-circle-outline\n                    "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1389795981
                    ),
                  },
                  [
                    _vm._v(" "),
                    !_vm.avazaTaskId
                      ? _c("span", [
                          _vm._v(
                            "There are Cost Unit designation issues in the schedule so Avaza Task creation is disabled."
                          ),
                          _c("br"),
                          _vm._v(
                            '\n                    "' +
                              _vm._s(_vm.costUnitIssues) +
                              "\n            "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "There are Cost Unit designation issues. The Avaza Task already exists but the issues must still be resolved in the schedule."
                          ),
                          _c("br"),
                          _vm._v(
                            '\n                    "' +
                              _vm._s(_vm.costUnitIssues) +
                              '"\n            '
                          ),
                        ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }