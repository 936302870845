import ApiService from "@/services/api.service";

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        schedule: [],
        loading: false, // must leave as false
        costUnits: [],
        costUnitSummary: {},
        determiningCostUnits: false,
    }
}

const getters = {
    schedule: (state) => state.schedule,
    loading: (state) => state.loading,
    costUnits: (state) => state.costUnits,
    costUnitSummary: (state) => state.costUnitSummary,
    determiningCostUnits: (state) => state.determiningCostUnits,
}

const actions = {
    async determineCostUnits({ commit, state, dispatch, rootGetters }) {

        commit('setDeterminingCostUnits', true)

        if ( !state.schedule.rows ) {
            return // schedule hasn't loaded so early silent exit and we'll watch for it to load
        }

        const type = rootGetters['ProjectRegister/details']['Type']
                        ?? rootGetters['ProjectsSingle/entry']['commercial_type']
                        ?? null

        if ( !type ) {
            dispatch(
                "Alert/setAlert",
                { message: "Error determining Cost Units",
                  errors: ["Project Type could not be determined. This may be an error in the Project Register or because the project setup hasn't been fully completed."],
                  type: "error" },
                { root: true }
            )
            return
        }

        let isReimbursable = null

        if ( type.includes("Lump Sum") && ( !type.includes("Rates") && !type.includes("Reimbursable") ) ) {
            isReimbursable = false
        }
        if ( ( type.includes("Rates") || type.includes("Reimbursable") ) && !type.includes("Lump Sum") ) {
            isReimbursable = true
        }
        // special override to allow manually (backend) entering 2x Avaza projects and deeming that a lump sum + reimbursable job regardless
        // of the type selected in the Project Register
        if (rootGetters['ProjectsSingle/entry'].avaza_lumpsum_project_id && rootGetters['ProjectsSingle/entry'].avaza_reimbursable_project_id) {
            isReimbursable = null
        }

        const costUnits = state.schedule.rows.filter((row) => {
            return row.cells[state.schedule.columnKeysByName["Cost Code"]].displayValue
        })
        .map((row) => ({
            ...row,
            section:                    null,
            task:                       null,
            isReimbursable:             isReimbursable ?? (row.cells[state.schedule.columnKeysByName["Reimbursable Activity"]].value == 1), // "Reimbursable Activity" is a future column in the schedule
            avazaCreation: {
                section: {
                    Title:              row.cells[state.schedule.columnKeysByName["AvazaSection"]].value,
                },
                task: {
                    Title:              row.cells[state.schedule.columnKeysByName["Cost Code"]].displayValue.toString()
                                            + " " + row.cells[state.schedule.columnKeysByName["Activity"]].value,

                    Description:        row.cells[state.schedule.columnKeysByName["WBS"]].value //intentionally the full WBS here
                                            + " " + row.cells[state.schedule.columnKeysByName["Cost Code"]].displayValue.toString()
                                            + " " + row.cells[state.schedule.columnKeysByName["Activity"]].value,

                    EstimatedEffort:    row.cells[state.schedule.columnKeysByName["Budget [hrs]"]].value,

                    DateStart:          row.cells[state.schedule.columnKeysByName["Start Date"]].value,

                    DateDue:            row.cells[state.schedule.columnKeysByName["Finish Date"]].value,
                }
            },

        }))

        commit('setCostUnits', costUnits)
        await dispatch('AvazaProject/fetchAvazaSectionsAndTasks', null, { root: true })

        const costUnitsLumpSum = state.costUnits.filter(costUnit => {
            return !costUnit.isReimbursable
        }) ?? []
        const costUnitsReimbursable = state.costUnits.filter(costUnit => {
            return costUnit.isReimbursable
        }) ?? []

        commit('setCostUnitSummary', {
            lumpSum: {
                totalCostUnits:     costUnitsLumpSum.length ?? 0,

                totalActualHours:   costUnitsLumpSum.reduce((currentTotal, costUnit) => {
                                        return (costUnit.cells[state.schedule.columnKeysByName["Actual [hrs]"]].value || 0) + currentTotal
                                    }, 0) ?? 0,

                totalBudgetHours:   costUnitsLumpSum.reduce((currentTotal, costUnit) => {
                                        return (costUnit.cells[state.schedule.columnKeysByName["Budget [hrs]"]].value || 0) + currentTotal
                                    }, 0) ?? 0,

                totalWithSection:   costUnitsLumpSum.filter(costUnit => costUnit.section).length ?? 0,
                totalWithTask:      costUnitsLumpSum.filter(costUnit => costUnit.task).length ?? 0,
            },
            reimbursable: {
                totalCostUnits:     costUnitsReimbursable.length ?? 0,

                totalActualHours:   costUnitsReimbursable.reduce((currentTotal, costUnit) => {
                                        return (costUnit.cells[state.schedule.columnKeysByName["Actual [hrs]"]].value || 0) + currentTotal
                                    }, 0) ?? 0,

                totalBudgetHours:   costUnitsReimbursable.reduce((currentTotal, costUnit) => {
                                        return (costUnit.cells[state.schedule.columnKeysByName["Budget [hrs]"]].value || 0) + currentTotal
                                    }, 0) ?? 0,

                totalWithSection:   costUnitsReimbursable.filter(costUnit => costUnit.section).length ?? 0,
                totalWithTask:      costUnitsReimbursable.filter(costUnit => costUnit.task).length ?? 0,
            }
        })

        commit('setDeterminingCostUnits', true)
    },
    async updateCostUnitsForAvaza({ state, commit, dispatch, rootGetters }, justFetched) {

        // get Avaza Sections & Tasks if not already, and return (because fetchAvazaSectionsAndTasks will reinitiate this routine)
        if( !justFetched ) {
            await dispatch('AvazaProject/fetchAvazaSectionsAndTasks', null, { root: true })
            return
        }

        let newCostUnits = _.cloneDeep(state.costUnits)
        newCostUnits.forEach(costUnit => {

            const type = costUnit.isReimbursable ? "reimbursable" : "lumpSum"

            let costUnitProjectIDFK = rootGetters['ProjectsSingle/entry'].avaza_lumpsum_project_id
            if (type === "reimbursable") costUnitProjectIDFK = rootGetters['ProjectsSingle/entry'].avaza_reimbursable_project_id

            costUnit.section = rootGetters['AvazaProject/sectionsAndTasks']?.sections
            .filter(section => {
                return section.ProjectIDFK == costUnitProjectIDFK
            })
            .find(section => {
                return section.Title == costUnit.cells[state.schedule.columnKeysByName["AvazaSection"]].value
            }) ?? null

            costUnit.task = rootGetters['AvazaProject/sectionsAndTasks']?.tasks
            .filter(task =>{
                return task.ProjectIDFK == costUnitProjectIDFK
            })
            .find(task => {
                return ( task.Title == costUnit.cells[state.schedule.columnKeysByName["Cost Code"]].displayValue.toString() + " " + costUnit.cells[state.schedule.columnKeysByName["Activity"]].value )
                && task.SectionTitle == costUnit.cells[state.schedule.columnKeysByName["AvazaSection"]].value
            }) ?? null
        })

        commit('setCostUnits', newCostUnits)

    },
    fetchScheduleData({ commit, dispatch }, params) {
        commit('setLoading', true)

        ApiService
            .get(`schedules/${params.smartsheetId}`)
            .then(response => {
                // console.log(response.data)
                commit('setSchedule', response.data[0])
            })
            .catch(error => {
                // console.log(error.response.data)
                dispatch(
                    "Alert/setAlert",
                    { message: "Error loading Project Schedule", errors: error.response.data, type: "error" },
                    { root: true }
                )
            })
            .finally(() => {
                if (params.thenDetermineCostUnits) dispatch('determineCostUnits')

                commit('setLoading', false)
            });
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setSchedule: set('schedule'),
    setLoading: set('loading'),
    setCostUnits: set('costUnits'),
    setCostUnitSummary: set('costUnitSummary'),
    setDeterminingCostUnits: set('determiningCostUnits'),
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
