var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.sheets,
      "item-key": "smartsheet_id",
      options: _vm.options,
      "server-items-length": _vm.pagination.total,
      "footer-props": {
        "items-per-page-options": [25, 50, 100, 250],
      },
      "items-per-page": 50,
      "show-select": "",
      "show-expand": "",
      dense: "",
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-row",
              { staticClass: "px-4" },
              [
                _c("span", { staticClass: "overline" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.cachingBatch.status) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mb-2",
                    attrs: {
                      disabled: _vm.batching,
                      outlined: _vm.cachingBatch.progress < 100,
                      color:
                        _vm.cachingBatch.progress < 100 ? "secondary" : "info",
                      small: "",
                    },
                    on: { click: _vm.updateSmartsheetsCache },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s("Update Cache") +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "pb-4" },
              [
                _c("v-text-field", {
                  staticClass: "mx-4",
                  attrs: {
                    label: "Search",
                    hint:
                      "Searches name, workspace, template, columns and summary fields only.",
                    "persistent-hint": "",
                  },
                  model: {
                    value: _vm.options.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "keyword", $$v)
                    },
                    expression: "options.keyword",
                  },
                }),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c("v-switch", {
                  staticClass: "mr-2",
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "overline" }, [
                            _vm._v('Starting "P" Only'),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.options.startingWithP,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "startingWithP", $$v)
                    },
                    expression: "options.startingWithP",
                  },
                }),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c("v-switch", {
                  staticClass: "mx-2",
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "overline" }, [
                            _vm._v("Templated Only"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.options.templated,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "templated", $$v)
                    },
                    expression: "options.templated",
                  },
                }),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c("v-switch", {
                  staticClass: "ml-2 mr-4",
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "overline" }, [
                            _vm._v("Hide Locked"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.options.hideLocked,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "hideLocked", $$v)
                    },
                    expression: "options.hideLocked",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-row", { staticClass: "pb-4 px-4" }, [
              _vm._v(
                "\n            Selected: " +
                  _vm._s(_vm.selectedSheets.length) +
                  "\n        "
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "item.data-table-select",
        fn: function (ref) {
          var item = ref.item
          var isSelected = ref.isSelected
          var select = ref.select
          return [
            item.column_names
              ? _c("v-simple-checkbox", {
                  attrs: { value: isSelected, ripple: false },
                  on: {
                    input: function ($event) {
                      return select($event)
                    },
                  },
                })
              : _vm._e(),
          ]
        },
      },
      {
        key: "item.status",
        fn: function (ref) {
          var item = ref.item
          return [
            Boolean(item.is_locked)
              ? _c(
                  "v-icon",
                  { attrs: { small: "", color: "orange lighten-2" } },
                  [_vm._v("mdi-lock")]
                )
              : _vm._e(),
            _vm._v(" "),
            !Boolean(item.is_loaded)
              ? _c("v-icon", { attrs: { small: "", color: "gray darken-2" } }, [
                  _vm._v("mdi-progress-clock"),
                ])
              : _vm._e(),
            _vm._v(" "),
            Boolean(item.is_loaded) && !Boolean(item.is_locked)
              ? _c(
                  "v-icon",
                  { attrs: { small: "", color: "green lighten-2" } },
                  [_vm._v("mdi-cloud-check")]
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "expanded-item",
        fn: function (ref) {
          var headers = ref.headers
          var item = ref.item
          return [
            _c(
              "td",
              { attrs: { colspan: headers.length } },
              [
                _c("v-simple-table", {
                  staticClass: "font-weight-light",
                  attrs: { dense: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "overline",
                                    staticStyle: { width: "20%" },
                                  },
                                  [_vm._v("Columns")]
                                ),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(item.column_names))]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "overline",
                                    staticStyle: { width: "20%" },
                                  },
                                  [_vm._v("Primary Column")]
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(item.primary_column_name)),
                                ]),
                              ]),
                              _vm._v(" "),
                              item.summary_fields
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "overline",
                                        staticStyle: { width: "20%" },
                                      },
                                      [_vm._v("Summary Fields")]
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(item.summary_fields)),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "overline",
                                    staticStyle: { width: "20%" },
                                  },
                                  [_vm._v("Smartsheet ID")]
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "caption",
                                      attrs: { href: item.permalink },
                                    },
                                    [_vm._v(_vm._s(item.smartsheet_id))]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              Boolean(item.resource_management_enabled)
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "overline",
                                        staticStyle: { width: "20%" },
                                      },
                                      [_vm._v("Res. Mgmt Enabled")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c("v-simple-checkbox", {
                                          attrs: {
                                            disabled: "",
                                            value: true,
                                            light: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.gte_template
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "overline",
                                        staticStyle: { width: "20%" },
                                      },
                                      [_vm._v("Template")]
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(item.gte_template)),
                                      item.gte_template_build_date
                                        ? _c("span", [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  item.gte_template_build_date
                                                ) +
                                                ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "overline",
                                    staticStyle: { width: "20%" },
                                  },
                                  [_vm._v("Owner")]
                                ),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(item.owner))]),
                              ]),
                              _vm._v(" "),
                              item.loaded_at
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "overline",
                                        staticStyle: { width: "20%" },
                                      },
                                      [_vm._v("Last Cached")]
                                    ),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.loaded_at))]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }