var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showSummaryLumpSum
        ? _c("Summary", { attrs: { type: "lumpSum" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSummaryReimbursable
        ? _c("Summary", { attrs: { type: "reimbursable" } })
        : _vm._e(),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.costUnits,
          itemsPerPage: 1000,
          "hide-default-footer": "",
          "item-key": "id",
          "group-by": _vm.wbsCellString,
          "fixed-header": "",
          "disable-sort": "",
          loading: _vm.loading,
        },
        scopedSlots: _vm._u([
          {
            key: "group.header",
            fn: function (ref) {
              var group = ref.group
              var headers = ref.headers
              var isOpen = ref.isOpen
              var toggle = ref.toggle
              return [
                _c(
                  "th",
                  { attrs: { colspan: headers.length } },
                  [
                    _c("v-icon", { on: { click: toggle } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(isOpen ? "mdi-minus" : "mdi-plus") +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "overline" }, [
                      _vm._v(_vm._s(group)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.isReimbursable",
            fn: function (ref) {
              var item = ref.item
              return [
                item.isReimbursable &&
                _vm.showSummaryLumpSum &&
                _vm.showSummaryReimbursable
                  ? _c(
                      "v-chip",
                      { attrs: { color: "green lighten-4", small: "" } },
                      [_vm._v("R")]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.CostUnit",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      item.cells[_vm.schedule.columnKeysByName["Cost Code"]]
                        .displayValue +
                        " " +
                        item.cells[_vm.schedule.columnKeysByName["Activity"]]
                          .value
                    ) +
                    "\n        "
                ),
              ]
            },
          },
          {
            key: "item.Budget",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      item.cells[_vm.schedule.columnKeysByName["Budget [hrs]"]]
                        .value
                    ) +
                    "\n        "
                ),
              ]
            },
          },
          {
            key: "item.Actuals",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      item.cells[
                        _vm.schedule.columnKeysByName["Actual [hrs]"]
                      ].value.toFixed(2)
                    ) +
                    "\n        "
                ),
              ]
            },
          },
          {
            key: "item.Avaza",
            fn: function (ref) {
              var item = ref.item
              return [
                item.task
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-2",
                        attrs: {
                          color:
                            item.cells[
                              _vm.schedule.columnKeysByName["Actual [hrs]"]
                            ].value == (item.task.ActualTime || 0)
                              ? "green lighten-1"
                              : "orange lighten-1",
                          small: "",
                          label: "",
                          outlined: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s((item.task.ActualTime || 0).toFixed(2)) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function (ref) {
              var item = ref.item
              return [_c("AvazaCreateSingle", { attrs: { item: item } })]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }