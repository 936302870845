import ApiService from "@/services/api.service";

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        data: [],
        query: {},
        selected: [],
        defining: false,
        loading: false,
    }
}

const getters = {
    data: state => state.data,
    defining: state => state.defining,
    loading: state => state.loading,
}

const actions = {
    fetchIndexData({ commit, state, dispatch }) {

        commit('setLoading', true)

        ApiService
            .get('avaza/projectRates', { params: state.query })
            .then(response => {
                // console.log(response.data)
                commit('setData', response.data)
            })
            .catch(error => {
                // console.log(error.response.data)
                dispatch(
                    "Alert/setAlert",
                    {
                        message: "Error loading rates table: "+error.response.data.message,
                        errors: [error.response.data.errors], type: "error"
                    },
                    { root: true }
                )
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    defineRates({ commit, dispatch }, params) {

        commit("setDefining", true);

        ApiService.post(`avaza/projectRates`, params)
            .then((response) => {

                if(response.data.failed ?? null) console.log(response.data.failed)

                dispatch(
                    "Alert/setAlert",
                    {
                        message: response.data.header,
                        type: response.data.type,
                        details: response.data.message
                    },
                    { root: true }
                )
            })
            .catch((error) => {
                // let message = error.data.message || error.message
                let message = error
                console.log(error)
                dispatch(
                    "Alert/setAlert",
                    { message: "Error", errors: message, type: "error" },
                    { root: true }
                );
            })
            .finally(() => {
                commit("setDefining", false)
                dispatch('fetchIndexData')
            });
    },
    setQuery({ commit }, value) {
        commit('setQuery', _.cloneDeep(value))
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setData: set('data'),
    setQuery: set('query'),
    setDefining: set('defining'),
    setLoading: set('loading'),
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
