import ApiService from "@/services/api.service";

function initialState() {
    return {
        entry: {
            id: null,
            gte_project_id: "",
            portal_manager_id: null,
            stage: null,
            delivery_status: null,
            estimate_smartsheet_id: null,
            workspace_id: null,
            schedule_smartsheet_id: null,
            avaza_lumpsum_project_id: null,
            avaza_reimbursable_project_id: null,
            commercial_type: null,
            project_manager_id: null,
            created_at: "",
            updated_at: "",
            deleted_at: "",
            last_synced: "",
        },
        loading: false,
    };
}

const getters = {
    entry: (state) => state.entry,
    loading: (state) => state.loading,
};

const actions = {
    storeData({ commit, state, dispatch }) {
        commit("setLoading", true);
        dispatch("Alert/resetState", null, { root: true });

        return new Promise((resolve, reject) => {
            let params = objectToFormData(state.entry, {
                indices: true,
                booleansAsIntegers: true,
            });
            ApiService.post(`projects`, params)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    let message = error.response.data.message || error.message;
                    let errors = error.response.data.errors;

                    dispatch(
                        "Alert/setAlert",
                        { message: message, errors: errors, type: "error" },
                        { root: true }
                    );

                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });
    },
    updateData({ commit, state, dispatch }, optPassedParams) {

        commit("setLoading", true);
        dispatch("Alert/resetState", null, { root: true });

        return new Promise((resolve, reject) => {

            let obj = optPassedParams ?? state.entry

            let params = objectToFormData(obj, {
                indices: true,
                booleansAsIntegers: true,
            })
            params.set("_method", "PUT");

            ApiService.post(`projects/${state.entry.gte_project_id}`, params)
                .then((response) => {
                    commit('setEntry', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    let message = error.response.data.message || error.message
                    let errors = error.response.data.errors

                    dispatch(
                        "Alert/setAlert",
                        { message: message, errors: errors, type: "error" },
                        { root: true }
                    );

                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false)
                });
        });
    },
    fetchData({ commit }, gteProjectId) {
        ApiService.get(`projects/${gteProjectId}`).then((response) => {
            commit("setEntry", response.data.data);
        });
    },
    fetchDataByScheduleId({ commit }, schedule_smartsheet_id) {
        ApiService.get(`projects/byScheduleId/${schedule_smartsheet_id}`).then((response) => {
            commit("setEntry", response.data.data);
        });
    },
    resetState({ commit }) {
        commit("resetState");
    },
};

const mutations = {
    setEntry(state, entry) {
        state.entry = entry
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
