<template>
    <div class="text-center">
        <v-dialog
            :value="message"
            width="500"
        >
            <v-card>

                <v-alert class="overline" :type="type || 'success'">
                    {{ message || "Note" }}
                </v-alert>

                <v-card-text>
                    <div v-if="errors" class="mt-1">
                        <p v-for="(value, key) in errors" :key="key" class="caption">
                            * {{ value }}
                        </p>
                    </div>
                    <div v-else class="mt-1">
                        <p class="caption">
                            {{ details }}
                        </p>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="resetState"
                    >
                        OK
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    computed: {
        ...mapGetters('Alert', ['message', 'details', 'errors', 'type'])
    },
    beforeDestroy() {
        this.resetState()
    },
    methods: {
        ...mapActions('Alert', ['resetState'])
    }
}
</script>

<style scoped></style>
