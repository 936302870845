var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Alert"),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "mb-5 elevation-1" },
        [
          _c(
            "v-card-title",
            [
              _vm._v(
                "\n            Project P" +
                  _vm._s(_vm.entry.gte_project_id) +
                  "\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-card-actions", [
                _vm.entry.avaza_lumpsum_project_id &&
                _vm.entry.avaza_reimbursable_project_id
                  ? _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "secondary",
                              text: "",
                              href:
                                "https://gtegroup.avaza.com/project/view/" +
                                _vm.entry.avaza_lumpsum_project_id,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Lump Sum\n                        "
                            ),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v("mdi-currency-usd"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "secondary",
                              text: "",
                              href:
                                "https://gtegroup.avaza.com/project/view/" +
                                _vm.entry.avaza_reimbursable_project_id,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Reimbursable\n                        "
                            ),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v("mdi-currency-usd"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm.entry.avaza_lumpsum_project_id ||
                      _vm.entry.avaza_reimbursable_project_id
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "secondary",
                                    text: "",
                                    href:
                                      "https://gtegroup.avaza.com/project/view/" +
                                        _vm.entry.avaza_lumpsum_project_id ||
                                      _vm.entry.avaza_reimbursable_project_id,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Avaza\n                            "
                                  ),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-currency-usd"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                _vm._v(" "),
                _vm.entry.avaza_lumpsum_project_id ||
                _vm.entry.avaza_reimbursable_project_id
                  ? _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              text: "",
                              href:
                                "/projects/" +
                                _vm.entry.gte_project_id +
                                "/timesheets",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Timesheets\n                        "
                            ),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v("mdi-timetable"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              text: "",
                              href:
                                "/projects/" +
                                _vm.entry.gte_project_id +
                                "/rates",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Rates\n                        "
                            ),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v("mdi-account-box-multiple"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [_c("RegisterDetails")], 1),
          _vm._v(" "),
          _c("v-card-text", [_c("CostUnits")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }