<template>
    <v-container>
        <span v-if='!loadingAvaza && !mapping'>
            <v-btn
                v-if='avazaTaskId'
                text x-small color='green accent-4'
                :href='`https://gtegroup.avaza.com/project/view/${avazaProjectId}`
                        + `#!tab=task-pane&groupby=ProjectSection&view=vertical&task=${avazaTaskId}&fileview=grid`'
                target='_blank'
            >
                Task {{ avazaTaskId }}
            </v-btn>

            <v-tooltip v-if='costUnitIssues' bottom>
                <template v-slot:activator='{ on }'>
                    <v-chip
                        v-on='on'
                        small icon
                        class='ma-2'
                        color='red'
                        text-color='white'
                    >
                        Issues
                        <v-icon right>
                            mdi-alert-circle-outline
                        </v-icon>
                    </v-chip>
                </template>
                <span v-if='!avazaTaskId'>There are Cost Unit designation issues in the schedule so Avaza Task creation is disabled.<br>
                        "{{ costUnitIssues }}
                </span>
                <span v-else>There are Cost Unit designation issues. The Avaza Task already exists but the issues must still be resolved in the schedule.<br>
                        "{{ costUnitIssues }}"
                </span>
            </v-tooltip>
        </span>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['item'],
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapGetters('ProjectsSingle', [
            'entry',
        ]),
        ...mapGetters('CostUnits', [
            'loadingAvaza',
            'mapping',
        ]),
        avazaTaskId() {
            return this.item.avaza?.id
        },
        avazaProjectId() {
            return this.item.type ? this.entry[`avaza_${this.item.type.toLowerCase()}_project_id`] : this.entry.avaza_lumpsum_project_id
        },
        costUnitIssues() {
            return this.item.issues_cost_unit
        },
    }
};
</script>
