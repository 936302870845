<template>
    <v-simple-table v-if="property.keyType">
        <template v-slot:default>
            <thead class="overline">
                <td>Existing Value</td>
                <td style="width: 20%">
                    {{ property.keyType }}
                </td>
            </thead>
            <tbody>
                <tr v-for="(value,ind) in property.values" :key="ind">
                    <td>{{ value }}</td>
                    <td>
                        {{ property.keyType == "Sheet"
                            ? getSheetName(ind)
                            : property.keys[ind]
                        }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ["name"],
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapGetters("SheetColumns", {
            properties: "properties",
        }),
        ...mapGetters("SmartsheetsCache", [
            "selectedSheets",
        ]),
        property() {
            return this.properties[this.name] ?? {
                keyType: null,
                keys: [],
                values: []
            }
        },
    },
    methods: {
        getSheetName(ind) {
            const sheet = this.selectedSheets.find(item => {
                return item.smartsheet_id == this.property.keys[ind]
            })
            if(!sheet) return "Unknown sheet"
            return sheet.name
        }
    },
};
</script>
