<template>
    <div>
        <v-banner single-line class="mb-2">
            Column Update

            <template v-slot:actions>
                <v-btn
                    v-if="addNewColumn || selectedColumns.length"
                    color="primary"
                    text
                    @click="addOrUpdateColumns"
                    :loading="updatingColumns"
                >
                    {{ setorModify }}
                </v-btn>
            </template>
        </v-banner>

        <v-alert v-if="error" border="top" color="red lighten-2" dark>
            {{ error }}
        </v-alert>

        <v-row no-gutters>
            <div class="overline">Selected sheets <span class="pr-2 caption">{{ selectedSheetsNames }}</span></div>
            <v-progress-linear
                v-if="loadingProperties"
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-row>

        <v-divider />
        <span class="overline">Column select</span>
        <v-row>
            <v-col cols="3">
                <v-switch v-model="addNewColumn" disabled>
                    <template v-slot:label>
                        <div class="overline">New column</div>
                    </template>
                </v-switch>
            </v-col>
            <v-col cols="9">
                <v-select
                    v-if="!addNewColumn"
                    v-model="selectedColumns"
                    :items="availableColumns"
                    :menu-props="{ maxHeight: '400' }"
                    label="Existing columns"
                    multiple
                    hint="Columns listed are only those common to the selected sheets."
                    no-data-text="No common columns found for the selected Smartsheets"
                    persistent-hint
                    small-chips
                    deletable-chips
                ></v-select>
            </v-col>

        </v-row>

        <ShowProperties v-if="!cantShowMeaningfulExistingProps" name="primary" />

        <!-- title -->
        <div v-if="addNewColumn || selectedColumns.length == 1">
            <v-divider />
            <v-row no-gutters>
                <v-col cols="12" md="3">
                    <v-switch v-model="setTitle">
                        <template v-slot:label>
                            <div class="overline">{{ setorModify }} title</div>
                        </template>
                    </v-switch>
                </v-col>
                <v-col cols="12" md="9">
                    <v-text-field
                        v-if="setTitle"
                        label="Title"
                        v-model="title"
                    ></v-text-field>
                </v-col>
            </v-row>
        </div>


        <div v-if="addNewColumn || selectedColumns.length">

            <!-- description -->
            <v-divider />
            <v-row no-gutters>
                <v-col cols="12" md="3">
                    <v-switch v-model="setDescription">
                        <template v-slot:label>
                            <div class="overline">{{ setorModify }} description</div>
                        </template>
                    </v-switch>
                </v-col>
                <v-col cols="12" md="9">
                    <v-text-field
                        v-if="setDescription"
                        label="Description"
                        persistent-hint
                        hint="Leave blank to clear the existing description"
                        v-model="description"
                    ></v-text-field>
                </v-col>
            </v-row>
            <ShowProperties v-if="!cantShowMeaningfulExistingProps" name="description" />

            <!-- visibility ('hidden') -->
            <v-divider />
            <v-radio-group v-model="hidden" row mandatory>
                <template v-slot:label>
                    <div class="overline">Visibility</div>
                </template>
                <v-radio v-if="!addNewColumn" value="unchanged">
                    <template v-slot:label>
                    <div class="caption">Unchanged</div>
                    </template>
                </v-radio>
                <v-radio
                    label="Hidden"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="Visible"
                    :value="false"
                ></v-radio>
            </v-radio-group>
            <ShowProperties v-if="!cantShowMeaningfulExistingProps" name="hidden" />

            <!-- locked -->
            <v-divider />
            <v-radio-group
                v-if="!setColumnFormula"
                v-model="locked"
                row
                mandatory
            >
                <template v-slot:label>
                    <div class="overline">Sub-Admin access</div>
                </template>
                <v-radio v-if="!addNewColumn" value="unchanged">
                    <template v-slot:label>
                    <div class="caption">Unchanged</div>
                    </template>
                </v-radio>
                <v-radio
                    label="Locked"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="Unlocked"
                    :value="false"
                ></v-radio>
            </v-radio-group>
            <ShowProperties v-if="!cantShowMeaningfulExistingProps" name="locked" />

            <!-- width -->
            <v-divider />
            <v-row no-gutters>
                <v-col cols="12" md="3">
                    <v-switch v-model="setWidth">
                        <template v-slot:label>
                            <div class="overline">{{ setorModify }} width</div>
                        </template>
                    </v-switch>
                </v-col>
                <v-col cols="12" md="9">
                    <v-text-field
                        v-if="setWidth"
                        label="Width"
                        v-model="width"
                    ></v-text-field>
                </v-col>
            </v-row>
            <ShowProperties v-if="!cantShowMeaningfulExistingProps" name="width" />
        </div>

        <div>
            <!-- formula -->
            <v-divider />
            <v-row no-gutters>
                <v-col cols="12">
                    <v-switch
                        v-model="setColumnFormula"
                        :disabled="!(addNewColumn || selectedColumns.length == 1)"
                    >
                        <template v-slot:label>
                            <div class="overline">{{ setorModify }} column-level formula</div>
                        </template>
                    </v-switch>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-textarea
                        v-if="setColumnFormula"
                        v-model="columnFormula"
                        name="columnFormula"
                        label="Formula"
                        persistent-hint
                        hint="Must start with an equal sign. First ensure the formula is correct in a test Smartsheet."
                        class="caption"
                    ></v-textarea>
                </v-col>
            </v-row>
            <ShowProperties v-if="!cantShowMeaningfulExistingProps" name="formula" />
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ShowProperties from "@/components/maintenance/subs/ShowProperties";

export default {
    components: {
        ShowProperties,
    },
    data() {
        return {
            error: "",

            addNewColumn: false,

            setTitle: false,
            setDescription: false,
            setWidth: false,
            setColumnFormula: false,
            setIndex: false, index: 0,

            title: null,
            description: null,
            hidden: "unchanged",
            locked: "unchanged",
            width: 30,
            columnFormula: null,
        };
    },
    // mounted() {
    //     this.fetchSchemas()
    // },
    computed: {
        ...mapGetters("SmartsheetsCache", [
            "selectedSheets",
            "schemas",
            "loadingSchemas"
        ]),
        ...mapGetters("SheetColumns", {
            properties: "properties",
            loadingProperties: "loading",
            updatingColumns: "updating",
        }),
        selectedColumns: {
            get () {
                return this.$store.state.SheetColumns.selectedColumns
            },
            set (newVal) {
                if (newVal.length) this.getColumns(newVal)
                return this.$store.dispatch('SheetColumns/setSelectedColumns', newVal)
            }
        },
        availableColumns() {
            const selectedSheetsColumns = this.selectedSheets.map(item => {
                return item.column_names ? item.column_names.substring(1).split("|") : null
            })
            if ( selectedSheetsColumns.length > 1 ) {
                // https://stackoverflow.com/questions/11076067/finding-matches-between-multiple-javascript-arrays
                var result = selectedSheetsColumns.shift().reduce(function(res, v) {
                    if (res.indexOf(v) === -1 && selectedSheetsColumns.every(function(a) {
                        return a.indexOf(v) !== -1;
                    })) res.push(v);
                    return res;
                }, []);

                // console.log(result)
                return result.sort()
            } else if ( selectedSheetsColumns.length == 1) {
                // console.log(selectedSheetsColumns[0])
                return selectedSheetsColumns[0]
            }
        },
        selectedSheetsNames() {
            const names = this.selectedSheets.map(item => item.name).join(', ')
            return names.length > 0 ? names : "None selected"
        },
        setorModify() {
            return this.addNewColumn ? "Set" : "Modify"
        },
        // settingsObject() {
        //     if (this.settings?.schedule?.columns &&
        //             this.selectedColumns.length == 1 || (this.addNewColumn && this.title)
        //         ) {
        //         const columnTitle = this.selectedColumns.length == 1 ? this.selectedColumns[0] : this.title

        //         return Object.values(this.settings.schedule.columns).filter(item => item.title == columnTitle).map((row) => ({
        //             ...row
        //         }))[0]
        //     }
        // },
        // columnFormula() {
        //     if (this.settingsObject && this.settingsObject['formulaReadable']) {
        //         return this.settingsObject['formulaReadable']
        //     }
        // },
        cantShowMeaningfulExistingProps() {
            return this.selectedSheets.map(item => item.smartsheet_id).length > 1 && this.selectedColumns.length > 1
        }
    },
    watch: {
        addNewColumn(newVal) {
            if (newVal) {
                this.setTitle = true
                this.setWidth = true
                this.setIndex = true
            }
        },
        selectedSheets() {
            this.selectedColumns = []
        }
    },
    methods: {
        ...mapActions("SmartsheetsCache", [
            "fetchSchemas"
        ]),
        ...mapActions("SheetColumns", [
            "fetchPropertiesForColumnsByTitle",
            "resetState",
            "updateColumnsByTitle"
        ]),
        getColumns(columnTitles) {
            const sheetIds = this.selectedSheets.map(item => item.smartsheet_id)

            if (this.cantShowMeaningfulExistingProps) {
                console.log("Will not attempt to load columns as there are both multiple sheets and multiple columns selected.")
                this.resetState()
                return
            }

            if (sheetIds.length > 10 || columnTitles.length > 10) {
                console.log("Will not attempt to load columns as there are too many columns and/or sheets selected. Limit is 10.")
                this.resetState()
                return
            }

            this.fetchPropertiesForColumnsByTitle({
                sheetIds,
                columnTitles,
            })

        },
        addOrUpdateColumns() {
            this.error = ""

            const params = {
                sheetIds: this.selectedSheets.map(item => item.smartsheet_id),
                columnTitles: this.selectedColumns,
                props: {}
            }
            if (this.setTitle && this.title) params.props['title'] = this.title
            if (this.setDescription) params.props['description'] = this.description
            if (this.hidden != 'unchanged') params.props['hidden'] = this.hidden
            if (this.locked != 'unchanged') params.props['locked'] = this.locked
            if (this.setWidth && this.width) params.props['width'] = this.width
            if (this.setIndex && this.index) params.props['index'] = this.index
            if (this.setColumnFormula) params.props['formula'] = this.columnFormula

            if ( _.isEmpty(params.props) ) {
                this.error = "No modifications have been specified."
                return
            }

            this.updateColumnsByTitle(params)
        }
    },
};
</script>


