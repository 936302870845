import ApiService from "@/services/api.service";

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        selectedColumns: [],
        properties: {},
        loading: false, // must leave as false
        updating: false,
    }
}

const getters = {
    selectedColumns: (state) => state.selectedColumns,
    properties: (state) => state.properties,
    loading: (state) => state.loading,
    updating: (state) => state.updating,
}

const actions = {
    fetchPropertiesForColumnsByTitle({ commit, state }, params) {

        commit('setLoading', true)

        ApiService
            .get(`sheets/getPropertiesForColumnsByTitle`, { params: params } )
            .then(response => {
                console.log(response.data)
                if( JSON.stringify(response.data.columns) !== JSON.stringify(state.selectedColumns) ) {
                    // the ships sailed: this is an old request so ignore the response
                    return
                }
                commit('setProperties', response.data.properties)

                if (response.data.errors.length) {
                    console.log(response.data.errors)
                }

                commit('setLoading', false)
            })

    },
    updateColumnsByTitle({ commit, dispatch }, params) {

        commit('setUpdating', true)

        ApiService
            .put(`sheets/updateColumnsByTitle`, { params: params } )
            .then(response => {

                console.log(response.data) // don't comment out

                if (response.data.errors.length) {
                    dispatch(
                        "Alert/setAlert",
                        {
                            message: "Errors queuing one or more column updates",
                            errors: ["There were errors. The job batch can be monitored as id:"+response.data.batchId, ...response.data.errors],
                            type: "error"
                        },
                        { root: true }
                    )
                    return
                }

                dispatch(
                    "Alert/setAlert",
                    {
                        message: "Column updates were queued successfully",
                        errors: ["There were no errors. The job batch can be monitored as id:"+response.data.batchId],
                        type: "success"
                    },
                    { root: true }
                )
            })
            .catch(error => {
                console.log(error.response)
                dispatch(
                    "Alert/setAlert",
                    { message: "Internal error", errors: error, type: "error" },
                    { root: true }
                )
            })
            .finally(() => {
                commit('setUpdating', false)
            })
    },
    createColumns({ commit }, params) {
        commit('setUpdating', true)

        ApiService
            .put(`sheets/createColumns`, { params: params } )
            .then(response => {
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                commit('setUpdating', false)
            })
    },
    setSelectedColumns({ commit }, value) {
        commit("setSelectedColumns", value);
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setSelectedColumns: set('selectedColumns'),
    setProperties: set('properties'),
    setLoading: set('loading'),
    setUpdating: set('updating'),
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
