var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loadingAnything
    ? _c(
        "v-card",
        { staticClass: "mx-auto mb-10 elevation-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "overline" },
            [
              _vm.entry.last_synced
                ? _c("span", { staticClass: "caption" }, [
                    _vm._v("Last synced: " + _vm._s(_vm.entry.last_synced)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              true
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.loadingAnything,
                            loading: _vm.syncing,
                            color: "secondary",
                            light: "",
                            text: "",
                            target: "_blank",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.hideScheduleColumns()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Hide Non Standard Columns\n                "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-autorenew"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              true
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.loadingAnything,
                            loading: _vm.syncing,
                            color: "secondary",
                            light: "",
                            text: "",
                            target: "_blank",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.syncActuals(_vm.type)
                            },
                          },
                        },
                        [
                          _vm._v("\n                Sync\n                "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-autorenew"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-simple-table", {
                attrs: { dense: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th"),
                              _vm._v(" "),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Budget (hrs)"),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Actual (hrs) on Schedule"),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Budget Remaining (hrs)"),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Unsynced Approved (hrs)"),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Future Period Approved (hrs)"),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Pending or Draft (hrs)"),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Rejected (hrs)"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tbody", [
                            _vm.hasReimbursable && _vm.hasLumpSum
                              ? _c("tr", [
                                  _c("td", [_vm._v("Reimbursable")]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.reimbursable
                                          .schBudgetTotalHrs
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.reimbursable
                                          .schActualTotalHrs
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          _vm.totals.reimbursable
                                            .BudgetRemTotalHrs < 0
                                            ? "red--text lighten-1"
                                            : "green--text lighten-1 ",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.totals.reimbursable
                                                .BudgetRemTotalHrs
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    Math.abs(
                                      _vm.totals.reimbursable
                                        .avzUnsyncedAppTotalHrs
                                    ) > 0.01
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "red--text lighten-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.totals.reimbursable
                                                    .avzUnsyncedAppTotalHrs
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        )
                                      : _c("span", [_vm._v("0.0")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.reimbursable
                                          .avzFutureAppTotalHrs
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.reimbursable
                                          .avzPendingTotalHrs
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.reimbursable
                                          .avzRejectedTotalHrs
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasReimbursable && _vm.hasLumpSum
                              ? _c("tr", [
                                  _c("td", [_vm._v("Lump Sum")]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.lumpSum.schBudgetTotalHrs
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.lumpSum.schActualTotalHrs
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          _vm.totals.lumpSum.BudgetRemTotalHrs <
                                          0
                                            ? "red--text lighten-1"
                                            : "green--text lighten-1 ",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.totals.lumpSum
                                                .BudgetRemTotalHrs
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    Math.abs(
                                      _vm.totals.lumpSum.avzUnsyncedAppTotalHrs
                                    ) > 0.01
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "red--text lighten-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.totals.lumpSum
                                                    .avzUnsyncedAppTotalHrs
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        )
                                      : _c("span", [_vm._v("0.0")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.lumpSum.avzFutureAppTotalHrs
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.lumpSum.avzPendingTotalHrs
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totals.lumpSum.avzRejectedTotalHrs
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("TOTAL")]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(
                                  _vm._s(_vm.totals.combined.schBudgetTotalHrs)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(
                                  _vm._s(_vm.totals.combined.schActualTotalHrs)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.totals.combined.BudgetRemTotalHrs < 0
                                        ? "red--text lighten-1"
                                        : "green--text lighten-1 ",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.totals.combined.BudgetRemTotalHrs
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                Math.abs(
                                  _vm.totals.combined.avzUnsyncedAppTotalHrs
                                ) > 0.01
                                  ? _c(
                                      "span",
                                      { staticClass: "red--text lighten-1" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.totals.combined
                                                .avzUnsyncedAppTotalHrs
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _c("span", [_vm._v("0.0")]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.totals.combined.avzFutureAppTotalHrs
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(
                                  _vm._s(_vm.totals.combined.avzPendingTotalHrs)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.totals.combined.avzRejectedTotalHrs
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  436330508
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }