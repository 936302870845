function getDefaultState() {
    return {
        message: null,
        errors: null,
        details: null,
        type: 'success'
    }
}

const getters = {
    message: state => state.message,
    errors: state => state.errors,
    details: state => state.details,
    type: state => state.type
}

const actions = {
    setMessage({ commit }, message) {
        commit('setMessage', message)
    },
    setErrors({ commit }, errors) {
        commit('setErrors', errors)
    },
    setDetails({ commit }, details) {
        commit('setDetails', details)
    },
    setType({ commit }, type) {
        commit('setType', type)
    },
    setAlert({ commit }, data) {
        commit('setMessage', data.message || null)
        commit('setErrors', data.errors || null)
        commit('setDetails', data.details || null)
        commit('setType', data.type || null)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setMessage(state, message) {
        state.message = message
    },
    setErrors(state, errors) {
        state.errors = errors
    },
    setDetails(state, details) {
        state.details = details
    },
    setType(state, type) {
        state.type = type || 'success'
    },
    resetState(state) {
        state = Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
