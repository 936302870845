<template>
    <v-app>
        <Alert />

        <v-card class="mb-5 elevation-1">
            <v-card-title>
                Project P{{ entry.gte_project_id }}
                <v-spacer />
                <v-card-actions>
                    <!-- <v-btn
                        v-if="schedule.permalink"
                        color="secondary"
                        text
                        :href="schedule.permalink"
                        target="_blank"
                    >
                        Schedule
                        <v-icon right>mdi-chart-gantt</v-icon>
                    </v-btn> -->

                    <span v-if="entry.avaza_lumpsum_project_id && entry.avaza_reimbursable_project_id">
                        <v-btn
                            color="secondary"
                            text
                            :href="'https://gtegroup.avaza.com/project/view/' + entry.avaza_lumpsum_project_id"
                            target="_blank"
                        >
                            Lump Sum
                            <v-icon right>mdi-currency-usd</v-icon>
                        </v-btn>
                        <v-btn
                            color="secondary"
                            text
                            :href="'https://gtegroup.avaza.com/project/view/' + entry.avaza_reimbursable_project_id"
                            target="_blank"
                        >
                            Reimbursable
                            <v-icon right>mdi-currency-usd</v-icon>
                        </v-btn>
                    </span>

                    <span v-else>
                        <span v-if="entry.avaza_lumpsum_project_id || entry.avaza_reimbursable_project_id">
                            <v-btn
                                color="secondary"
                                text
                                :href="'https://gtegroup.avaza.com/project/view/' + entry.avaza_lumpsum_project_id || entry.avaza_reimbursable_project_id"
                                target="_blank"
                            >
                                Avaza
                                <v-icon right>mdi-currency-usd</v-icon>
                            </v-btn>
                        </span>
                    </span>
                    <span v-if="entry.avaza_lumpsum_project_id || entry.avaza_reimbursable_project_id">
                        <v-btn
                            color="primary"
                            text
                            :href="'/projects/' + entry.gte_project_id +'/timesheets'"
                        >
                            Timesheets
                            <v-icon right>mdi-timetable</v-icon>
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            :href="'/projects/' + entry.gte_project_id +'/rates'"
                        >
                            Rates
                            <v-icon right>mdi-account-box-multiple</v-icon>
                        </v-btn>
                    </span>
                </v-card-actions>
            </v-card-title>

            <v-card-text>
                <RegisterDetails />
            </v-card-text>

            <v-card-text>
                <CostUnits />
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RegisterDetails from "@/components/projects/RegisterDetails";
import CostUnits from "@/components/projects/CostUnits";

export default {
    props: ["gteProjectId"],
    components: {
        RegisterDetails,
        CostUnits,
    },
    created() {
        this.resetState()
        this.fetchData(this.gteProjectId)
    },
    beforeDestroy() {
        this.resetState()
    },
    computed: {
        ...mapGetters("ProjectsSingle", ["entry"]),
        ...mapGetters('ProjectRegister', ["details"]),
    },
    methods: {
        ...mapActions("ProjectsSingle", [
            "fetchData",
            "resetState",
        ]),
    }
};
</script>
