var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CostUnitSummary"),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          attrs: { grow: "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab", { key: "cost_units" }, [_vm._v("Cost Units")]),
          _vm._v(" "),
          _vm.extraneousAvazaTasks.length
            ? _c("v-tab", { key: "extra_avaza" }, [_vm._v("Extra Avaza Tasks")])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { key: "cost_units", attrs: { flat: "", eager: true } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.scheduleCostUnits,
                  itemsPerPage: 1000,
                  "item-class": _vm.itemRowBackground,
                  "hide-default-footer": "",
                  "item-key": "id",
                  "group-by": "avaza_section",
                  "sort-by": "cost_code",
                  "disable-sort": "",
                  "fixed-header": "",
                  loading: _vm.loadingAnything,
                  "loading-text": "Loading schedule cost units...",
                },
                scopedSlots: _vm._u([
                  {
                    key: "group.header",
                    fn: function (ref) {
                      var group = ref.group
                      var headers = ref.headers
                      var isOpen = ref.isOpen
                      var toggle = ref.toggle
                      return [
                        _c(
                          "th",
                          { attrs: { colspan: headers.length } },
                          [
                            _c("v-icon", { on: { click: toggle } }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(isOpen ? "mdi-minus" : "mdi-plus") +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "overline" }, [
                              _vm._v(_vm._s(group)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.icons",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm.entry.commercial_type == "Lump Sum + Rates"
                          ? _c(
                              "v-chip",
                              {
                                attrs: {
                                  color:
                                    item.type == "lumpSum"
                                      ? "orange lighten-4"
                                      : "green lighten-4",
                                  small: "",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.type.substring(0, 1).toUpperCase()
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.activity_id",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-chip-group",
                          { attrs: { column: "" } },
                          [
                            _c(
                              "v-chip",
                              {
                                staticClass: "pa-1 flex-grow-1 justify-center",
                                attrs: {
                                  color: "blue-grey",
                                  small: "",
                                  label: "",
                                  outlined: "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.activity_id) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.activity",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        !item.avaza ||
                        item.cost_code + " " + item.activity == item.avaza.name
                          ? _c(
                              "span",
                              {
                                staticClass: "caption py-1",
                                staticStyle: { "line-height": "100%" },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.activity) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              {
                                                staticClass: "caption py-1",
                                                staticStyle: {
                                                  "line-height": "100%",
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(item.activity) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [_vm._v("*")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    'The Avaza name for this Cost Unit differs. The Avaza task name is "' +
                                      _vm._s(item.avaza.name) +
                                      '".'
                                  ),
                                ]),
                              ]
                            ),
                      ]
                    },
                  },
                  {
                    key: "item.budget_hours",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        !item.avaza ||
                        item.budget_hours == item.avaza.budget_hours
                          ? _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.budget_hours.toFixed(1)) +
                                  "\n                    "
                              ),
                            ])
                          : _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              { staticClass: "red--text" },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    item.budget_hours.toFixed(1)
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "The Avaza budget for this Cost Unit differs. The Avaza task budget is " +
                                      _vm._s(
                                        item.avaza.budget_hours.toFixed(1)
                                      ) +
                                      "."
                                  ),
                                ]),
                              ]
                            ),
                      ]
                    },
                  },
                  {
                    key: "item.actual_hours",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        !item.hasOwnProperty("avaza") ||
                        Math.abs(
                          item.actual_hours - item.avaza.recorded_hours
                        ) < 0.001
                          ? _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    item.actual_hours
                                      ? item.actual_hours.toFixed(1)
                                      : null
                                  ) +
                                  "\n                    "
                              ),
                            ])
                          : _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-badge",
                                            {
                                              attrs: {
                                                color: "orange lighten-1",
                                                dot: "",
                                              },
                                            },
                                            [
                                              _c("span", _vm._g({}, on), [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      item.actual_hours
                                                        ? item.actual_hours.toFixed(
                                                            1
                                                          )
                                                        : null
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v("There is additional time "),
                                  _c("strong", [_vm._v("recorded")]),
                                  _vm._v(
                                    " in Avaza. This may be unapproved, or beyond the end of the last period."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "This totals " +
                                      _vm._s(
                                        (
                                          item.avaza.recorded_hours -
                                          item.actual_hours
                                        ).toFixed(1)
                                      ) +
                                      " hours."
                                  ),
                                ]),
                              ]
                            ),
                      ]
                    },
                  },
                  {
                    key: "item.budgetRemHrs",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            class:
                              item.budget_hours - item.actual_hours < 0
                                ? "red--text lighten-1"
                                : "green--text lighten-1 ",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  (
                                    item.budget_hours - item.actual_hours
                                  ).toFixed(1)
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.complete_pc",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s((item["complete_%"] * 100).toFixed(0)) +
                            "%\n                "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.unsyncedApproved",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.avaza
                          ? _c("CostUnitAvaza", {
                              attrs: {
                                item: item,
                                timeType: "unsyncedApproved",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.futureApproved",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.avaza
                          ? _c("CostUnitAvaza", {
                              attrs: { item: item, timeType: "futureApproved" },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.pendingOrDraft",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.avaza
                          ? _c("CostUnitAvaza", {
                              attrs: { item: item, timeType: "pendingOrDraft" },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.rejected",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.avaza
                          ? _c("CostUnitAvaza", {
                              attrs: { item: item, timeType: "rejected" },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.avazaTask",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.avaza
                          ? _c("CostUnitMappedTask", { attrs: { item: item } })
                          : _c("CostUnitCreateTask", { attrs: { item: item } }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            { key: "extra_avaza", attrs: { flat: "" } },
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Name"),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Budget"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              { staticClass: "text-left" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                {
                                                  staticStyle: {
                                                    "line-height": "100%",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                                        Recorded Time (hrs)\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "Includes all time regardless of approval status and time period."
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.extraneousAvazaTasks, function (item) {
                            return _c("tr", { key: item.name }, [
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        "x-small": "",
                                        color: "green accent-4",
                                        href:
                                          "https://gtegroup.avaza.com/project/view/" +
                                          (item.type
                                            ? _vm.entry[
                                                "avaza_" +
                                                  item.type.toLowerCase() +
                                                  "_project_id"
                                              ]
                                            : _vm.entry
                                                .avaza_lumpsum_project_id) +
                                          "#!tab=task-pane&groupby=ProjectSection&view=vertical&task=" +
                                          item.id +
                                          "&fileview=grid",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Task " +
                                          _vm._s(item.id) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.budget_hours))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.recorded_hours))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }