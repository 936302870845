var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Alert"),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "mb-2", attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "py-0" },
            [
              _c("span", { staticClass: "overline" }, [
                _vm._v("Procurement & Equipment"),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-card-actions"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-card-title", [_vm._v("Generate Lab Equipment Label CSV")]),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "caption",
                attrs: {
                  label: "Columns",
                  "persistent-hint": "",
                  hint:
                    "Comma-separated list of names. Names that do not exactly match the Smartsheet will be ignored.",
                },
                model: {
                  value: _vm.equipLabelColumnNames,
                  callback: function ($$v) {
                    _vm.equipLabelColumnNames = $$v
                  },
                  expression: "equipLabelColumnNames",
                },
              }),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "caption",
                attrs: {
                  label: "Rows",
                  value: "",
                  "persistent-hint": "",
                  hint:
                    "Specify range of row numbers, eg '122-125'; and/or comma-separated row numbers.",
                },
                model: {
                  value: _vm.equipLabelRowNums,
                  callback: function ($$v) {
                    _vm.equipLabelRowNums = $$v
                  },
                  expression: "equipLabelRowNums",
                },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    text: "",
                    disabled: !_vm.equipLabelRowNums,
                    loading: _vm.generating,
                  },
                  on: { click: _vm.generateCSV },
                },
                [_vm._v("\n                Download CSV\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }