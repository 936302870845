<template>
    <div>
        <v-banner single-line class="mb-2">
            <v-icon slot="icon" color="yellow accent-4" size="36">mdi-alarm-light</v-icon>
            Test Page

            <template v-slot:actions>
                <v-btn color="secondary" text @click="fire" :loading="loading">
                    Fire
                </v-btn>
            </template>
        </v-banner>

        <p>Sheets processed: {{ processed }} of {{ sheetCount }}</p>

        <v-data-table
            :headers="headers"
            :items="sheets"
            item-key="id"
            :search="search"
            :items-per-page="1000"
            :loading="loading"
            show-expand
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Search"
                    class="mx-4"
                ></v-text-field>
            </template>

            <template v-slot:item.countHypenEmail="{ item }">
                <v-chip
                    v-if="item.searchFound.hypenEmail && item.searchFound.hypenEmail.length"
                    class="ma-2"
                    color="red darken-2"
                    small
                    label
                    outlined
                >
                    {{ item.searchFound.hypenEmail.length }}
                </v-chip>
            </template>
            <template v-slot:item.countSsEmail="{ item }">
                <v-chip
                    v-if="item.searchFound.ssEmail && item.searchFound.ssEmail.length"
                    class="ma-2"
                    color="red darken-2"
                    small
                    label
                    outlined
                >
                    {{ item.searchFound.ssEmail.length }}
                </v-chip>
            </template>
            <template v-slot:item.countVLookup="{ item }">
                <v-chip
                    v-if="item.searchFound.vLookup && item.searchFound.vLookup.length"
                    class="ma-2"
                    color="red darken-2"
                    small
                    label
                    outlined
                >
                    {{ item.searchFound.vLookup.length }}
                </v-chip>
            </template>

            <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="p-0">
                    <div
                        v-if="item.searchFound.hypenEmail && item.searchFound.hypenEmail.length"
                        class="font-weight-black"
                    >
                        Emails with "gte-group"
                        <span class="caption">
                            {{ item.searchFound.hypenEmail }}
                        </span>
                    </div>
                    <div
                        v-if="item.searchFound.ssEmail && item.searchFound.ssEmail.length"
                        class="font-weight-black"
                    >
                        Emails with ".ss"
                        <span class="caption">
                            {{ item.searchFound.ssEmail }}
                        </span>
                    </div>
                    <div v-if="item.searchFound.vLookup && item.searchFound.vLookup.length" class="font-weight-black">
                        VLOOKUPs
                        <span class="caption">
                            {{ item.searchFound.vLookup }}
                        </span>
                    </div>
                </td>
            </template>
        </v-data-table>

        <!-- <v-text-field v-model="text1"></v-text-field> -->
        <!-- <v-text-field v-model="text2"></v-text-field> -->

    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ApiService from "@/services/api.service";

export default {
    data() {
        return {
            headers: [
                { text: "Name", value: "name" },
                { text: "Processed?", value: "processed" },
                { text: "gte-group", value: "countHypenEmail", align: "center"  },
                { text: ".ss", value: "countSsEmail", align: "center"  },
                { text: "VLOOKUP", value: "countVLookup", align: "center" },
            ],
            // text1: "Not used",
            // text2: null,
            loading: false,
            search: "",
            sheets: [],
            sheetCount: 0,
            processed: 0,
        };
    },
    methods: {
        fire() {
            this.loading = true

            // get sheets list
            ApiService
                .get(`sheets`)
                .then(async response => {
                    // console.log(response.data.data)
                    response.data.data.forEach(sheet => {
                        sheet.processed = null
                        sheet.searchFound = {
                            hyphenEmail: [],
                            ssEmail: [],
                            vLookup: [],
                        }
                    })
                    this.sheets = [
                        ...response.data.data
                    ]
                    this.sheetCount = response.data.totalCount

                    // for each sheet, get sheet and search
                    this.sheets.forEach( async sheet => { // await this.sheets.forEach(sheet => {
                        this.getSheet(sheet)
                    })
                    this.loading = false
                });

        },
        getSheet(sheet) {
            ApiService
            .get(`sheets/${sheet.id}`)
            .then((response) => {
                // console.log(response.data)

                const sheetDetails = response.data

                this.searchItem(sheetDetails.id, sheetDetails, {
                    hyphenEmail: "gte-group",
                    ssEmail: ".ss@",
                    vLookup: "VLOOKUP",
                })

                sheet['processed'] = "YES"
                this.processed++
            });
        },
        searchItem(sheetId, haystack, searchObj) {
            let sheet = this.sheets.find(sh => {
                return sh.id === sheetId
            })

            if ( !sheet.hasOwnProperty('searchFound') ) sheet.searchFound = {}

            Object.keys(haystack).forEach(key => {

                if (typeof haystack[key] === "object") {
                    this.searchItem(sheetId, haystack[key], searchObj)
                }

                if (typeof haystack[key] === "string") {
                    Object.keys(searchObj).forEach((searchName) => {

                        let searchAsRegEx = new RegExp(searchObj[searchName], "gi");

                        if (haystack[key].match(searchAsRegEx)) {
                            sheet.searchFound.hasOwnProperty(searchName)
                                ? sheet.searchFound[searchName].push(haystack[key])
                                : sheet.searchFound[searchName] = [ haystack[key] ]
                        }
                    })
                }
            })
        }
    },
};
</script>
