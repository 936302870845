<template>
    <v-app>
        <Alert />
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="data"
            item-key="key"
            fixed-header
            disable-pagination
            hide-default-footer
            show-select
            dense
            :sort-by="['avazaProject', 'role', 'location']"
            :sort-desc="[false, false, false]"
            multi-sort
            :loading="loading"
            :search="search"
        >

            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>P{{ gteProjectId }} Timesheet (Role) Categories</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        v-if="!loading"
                    ></v-text-field>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="primary"
                            dark
                            small
                            v-bind="attrs"
                            v-on="on"
                            :loading="defining"
                            :disabled="loading"
                            >
                            Create/Update Rates
                                <v-icon right dark>
                                    mdi-menu-down
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, key) in {'latest': 'Using Latest Rates', 'estimate': 'Using Estimate Rates'}"
                                :key="key">
                                <v-list-item-title>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="defineRates({
                                            'gteProjectId': gteProjectId,
                                            'type': key,
                                            'roleCategories': selected,
                                        })"
                                    >
                                        {{ item }}
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
            </template>

            <template v-slot:item.avazaRate="{ item }">
                <v-tooltip top v-if="(!item.estimateRate > 0 && !item.latestRate > 0) && !(item.avazaRate == 0 || item.avazaRate == null)">
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on"
                            class="d-flex justify-center" label color="red" text-color="white"
                            :href="'https://gtegroup.avaza.com/project/view/'+item.avazaProjectId"
                        >{{ item.avazaRate | currency }}</v-chip>
                    </template>
                    <span>Role Category does not have a defined rate in either of the estimate or latest rates tables.</span>
                </v-tooltip>
                <v-tooltip top v-else-if="item.avazaRate == 0 || item.avazaRate == null">
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on"
                            class="d-flex justify-center" label color="orange" text-color="white"
                            :href="'https://gtegroup.avaza.com/project/view/'+item.avazaProjectId"
                        >$0.00</v-chip>
                    </template>
                    <span>Role Category is established without a rate.</span>
                </v-tooltip>
                <v-tooltip top v-else-if="item.avazaRate < item.latestRate">
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on"
                            class="d-flex justify-center" label color="white" text-color="orange"
                            :href="'https://gtegroup.avaza.com/project/view/'+item.avazaProjectId"
                        >{{ item.avazaRate | currency }}</v-chip>
                    </template>
                    <span>Project rate for the Role Category is lower than the latest rate.</span>
                </v-tooltip>
                <v-tooltip top v-else-if="item.avazaRate === item.latestRate">
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on"
                            class="d-flex justify-center" label color="green" text-color="white"
                            :href="'https://gtegroup.avaza.com/project/view/'+item.avazaProjectId"
                        >{{ item.avazaRate | currency }}</v-chip>
                    </template>
                    <span>Project rate for the Role Category is equal to the latest rate.</span>
                </v-tooltip>
                <v-tooltip top v-else-if="item.avazaRate > item.latestRate">
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on"
                                class="d-flex justify-center" label color="white" text-color="red"
                                :href="'https://gtegroup.avaza.com/project/view/'+item.avazaProjectId"
                            >{{ item.avazaRate | currency }}</v-chip>
                    </template>
                    <span>Project rate for the Role Category exceeds even the latest rate.</span>
                </v-tooltip>
            </template>
            <template v-slot:item.estimateRate="{ item }">
                <a v-bind:href="item.estimateLink">{{ item.estimateRate | currency }}</a>
            </template>
            <template v-slot:item.latestRate="{ item }">
                <a v-bind:href="item.latestLink">{{ item.latestRate | currency }}</a>
            </template>

            <template v-slot:item.avazaProject="{ item }">
                <v-chip
                    v-if="item.avazaProject === 'Reimbursable'"
                    color="green lighten-4" small
                >R</v-chip>
                <v-chip
                    v-else-if="item.avazaProject === 'Lump Sum'"
                    color="blue lighten-4" small
                >LS</v-chip>
            </template>

        </v-data-table>
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: ["gteProjectId"],
    data() {
        return {
            search: "",
            selected: [],
            headers: [
                { text: "Role", value: "role" },
                { text: "Location", value: "location" },
                { text: "Commercial Type", value: "avazaProject" },
                { text: "Avaza Rate", value: "avazaRate", align: 'center' },
                { text: "Estimate Rate", value: "estimateRate", align: 'center' },
                { text: "Latest Rate", value: "latestRate", align: 'center' },
            ],
            options: {
                gteProjectId: this.gteProjectId,
            },
        };
    },
    created() {
        this.setQuery(this.options);
        this.fetchIndexData();
    },
    beforeDestroy() {
        this.resetState();
    },
    computed: {
        ...mapGetters("AvazaProjectRates", [
            "data",
            "defining",
            "loading",
        ]),
    },
    watch: {
        options: {
            handler() {
                this.setQuery(this.options);
                this.fetchIndexData();
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions("AvazaProjectRates", [
            "fetchIndexData",
            "defineRates",
            "setQuery",
            "resetState",
        ])
    },
}
</script>
