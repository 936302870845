import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import ProjectsSingle from './cruds/Projects/single'
import ProjectsIndex from './cruds/Projects/index'

import AvazaProject from './modules/Avaza/project'
import AvazaProjectRates from './modules/Avaza/project/rates'
import AvazaProjectTimesheets from './modules/Avaza/project/timesheets'

import Schedule from './modules/Smartsheet/schedule'
import CostUnits from './modules/Smartsheet/costUnits'
import ProjectRegister from './modules/Smartsheet/projectRegister'

import SmartsheetsCache from './modules/Cache/smartsheets'
import SheetColumns from './modules/Smartsheet/columns'

import Alert from './modules/alert'

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    ProjectsSingle,
    ProjectsIndex,

    AvazaProject,
    AvazaProjectTimesheets,
    AvazaProjectRates,

    Schedule,
    CostUnits,
    ProjectRegister,

    SmartsheetsCache,
    SheetColumns,

    Alert,
  },
  strict: debug
})
