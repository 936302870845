<template>
        <v-app>
        <Alert />

        <v-card
            outlined
            class="mb-2"
        >
            <v-card-title class="py-0">
                <span class="overline">Procurement &amp; Equipment</span>
                <v-spacer />
                <v-card-actions>
                    <!-- <v-btn
                        color="orange"
                        text
                        :href="'/projects/'+xx.id"
                    >
                        <v-icon>mdi-xxx</v-icon>
                    </v-btn> -->
                </v-card-actions>
            </v-card-title>

            <v-card-text>

                <v-card-title>Generate Lab Equipment Label CSV</v-card-title>

                <v-text-field
                    v-model="equipLabelColumnNames"
                    label="Columns"
                    persistent-hint
                    hint="Comma-separated list of names. Names that do not exactly match the Smartsheet will be ignored."
                    class="caption"
                ></v-text-field>

                <v-text-field
                    v-model="equipLabelRowNums"
                    label="Rows"
                    value=""
                    persistent-hint
                    hint="Specify range of row numbers, eg '122-125'; and/or comma-separated row numbers."
                    class="caption"
                ></v-text-field>

                <v-btn
                        color="primary"
                        text
                        @click="generateCSV"
                        :disabled="!equipLabelRowNums"
                        :loading="generating"
                    >
                    Download CSV
                </v-btn>

            </v-card-text>

        </v-card>
    </v-app>
</template>

<script>
import ApiService from "@/services/api.service"
// import downloadCsv from 'vue-json-csv'

export default {
    components: {
        // downloadCsv
    },
    data() {
        return {
            data: [],
            labels: [],
            generating: false,
            equipLabelColumnNames: "Equipment ID, Description / Device, Project ID, Reference or Tag",
            equipLabelRowNums: null,
        };
    },
    methods: {
        generateCSV() {
            this.generating = true;
            ApiService
                .post(`getEquipmentLabelsCsv`, {
                    columnNames: this.equipLabelColumnNames,
                    rowNumbers: this.equipLabelRowNums,
                })
                .then(response => {
                    this.generating = false
                    window.open(response.data)
                })
        }
    }
};
</script>
