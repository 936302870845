import ApiService from "@/services/api.service";

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        data: [],
        pagination: 0,
        query: {},
        loading: false
    }
}

const getters = {
    data: state => state.data,
    pagination: state => state.pagination,
    loading: state => state.loading
}

const actions = {
    fetchIndexData({ commit, state }) {

        commit('setLoading', true)

        ApiService
            .get('projects', { params: state.query })
            .then(response => {
                commit('setData', response.data.data)
                commit('setPagination', {
                    'current_page': response.data.current_page,
                    'per_page': response.data.per_page,
                    'total': response.data.total
                })
            })
            .catch(error => {
                console.log(error)
                message = error.response.data.message || error.message
                // TODO error handling
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    setQuery({ commit }, value) {
        commit('setQuery', _.cloneDeep(value))
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setData: set('data'),
    setPagination: set('pagination'),
    setQuery: set('query'),
    setLoading: set('loading'),
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
