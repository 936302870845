<template>
    <v-container>
        <span v-if='createPermissive'>
            <v-btn
                text
                x-small
                color='orange accent-4'
                @click='createTask()'
                :disabled='creating'
            >
                Create Task
            </v-btn>
        </span>
    </v-container>
</template>

<script>
import ApiService from '@/services/api.service'
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['item'],
    data() {
        return {
            creating: false
        };
    },
    computed: {
        ...mapGetters('ProjectsSingle', [
            'entry',
        ]),
        ...mapGetters('CostUnits', [
            'loadingAnything',
        ]),
        avazaTaskId() {
            return this.item?.avaza?.id
        },
        avazaProjectId() {
            return this.item?.type ? this.entry[`avaza_${this.item.type.toLowerCase()}_project_id`] : this.entry.avaza_lumpsum_project_id
        },
        costUnitIssues() {
            return this.item?.issues_cost_unit
        },
        createPermissive() {
            return this.avazaProjectId && !this.loadingAnything && !this.costUnitIssues
        }
    },
    methods: {
        ...mapActions('CostUnits', [
            'fetchAvazaCostUnits',
        ]),
        ...mapActions('Alert', [
            'setAlert',
        ]),
        createTask() {
            this.creating = true

            ApiService
                .post(`projects/avaza/task/create`, {
                    projectId: this.avazaProjectId,
                    taskName: this.item.cost_code + ' ' + this.item.activity,
                    sectionName: this.item.avaza_section,
                    tagsArray: [this.item.activity_id],
                    budgetHours: this.item.budget_hours,
                })
                .then(response => {
                    console.log(response.data)
                    this.fetchAvazaCostUnits()
                })
                .catch(error => {
                    console.log(error.response.data)
                    this.setAlert({
                        message: 'Failed to create Avaza Task',
                        errors: error.response.data,
                        type: 'error'
                    })
                })
        },
    }
};
</script>
