require("./bootstrap");

require("alpinejs");

import Vue from "vue";
import Store from '@/store'
import Vuetify from '@/plugins/vuetify'
import Vue2Filters from 'vue2-filters'

import GlobalComponents from '@/globalComponents'
Vue.use(GlobalComponents)
Vue.use(Vue2Filters)

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import ApiService from '@/services/api.service'
ApiService.init();

new Vue({
    store: Store,
    vuetify: Vuetify,
}).$mount("#app");

