<template>
        <v-app>
        <Alert />

        <v-card
            outlined
            class="mb-2"
        >
            <v-card-title class="py-0">
                <span class="overline">Maintenance</span>
                <v-spacer />
                <v-card-actions>
                    <!-- <v-btn
                        color="orange"
                        text
                        :href="'/projects/'+xx.id"
                    >
                        <v-icon>mdi-xxx</v-icon>
                    </v-btn> -->
                </v-card-actions>
            </v-card-title>

            <v-card-text>

                <v-tabs v-model="tab" fixed-tabs>
                    <v-tab v-for="item in items" :key="item.tab">
                        {{ item.tab }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="item in items" :key="item.tab" :eager="true">
                        <v-card flat>
                            <v-card-text>
                                <component
                                    v-bind:is="item.content"
                                    :smartsheet-id="smartsheetId"
                                ></component>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

            </v-card-text>

        </v-card>
    </v-app>
</template>

<script>
import CachedSheets from "@/components/maintenance/CachedSheets";
import ColumnSelection from "@/components/maintenance/ColumnSelection";
// import SummaryFieldSelection from "./SummaryFieldSelection";

export default {
    props: ["smartsheetId"],
    components: {
        CachedSheets,
        ColumnSelection,
        // SummaryFieldSelection,
    },
    data() {
        return {
            tab: null,
            items: [
                { tab: "Sheets", content: "CachedSheets" },
                { tab: "Columns", content: "ColumnSelection" },
                // { tab: "Summary Fields", content: "SummaryFieldSelection" },
            ],
        };
    },
};
</script>
