var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        { attrs: { value: _vm.message, width: "500" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-alert",
                {
                  staticClass: "overline",
                  attrs: { type: _vm.type || "success" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.message || "Note") +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _vm.errors
                  ? _c(
                      "div",
                      { staticClass: "mt-1" },
                      _vm._l(_vm.errors, function (value, key) {
                        return _c("p", { key: key, staticClass: "caption" }, [
                          _vm._v(
                            "\n                        * " +
                              _vm._s(value) +
                              "\n                    "
                          ),
                        ])
                      }),
                      0
                    )
                  : _c("div", { staticClass: "mt-1" }, [
                      _c("p", { staticClass: "caption" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.details) +
                            "\n                    "
                        ),
                      ]),
                    ]),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.resetState },
                    },
                    [_vm._v("\n                    OK\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }