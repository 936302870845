import ApiService from "@/services/api.service";

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        data: [],
        pagination: 0,
        query: {},
        loading: false
    }
}

const getters = {
    data: state => state.data,
    pagination: state => state.pagination,
    loading: state => state.loading
}

const actions = {
    fetchIndexData({ commit, state }) {

        commit('setLoading', true)

        ApiService
            .get('avaza/timesheets', { params: state.query })
            .then(response => {
                // console.log(response.data)
                commit('setData', response.data)
                commit('setPagination', {
                    'current_page': response.data.PageNumber,
                    'per_page': response.data.PageSize,
                    'total': response.data.TotalCount
                })
            })
            .catch(error => {
                console.log(error)
                message = error.response.data.message || error.message
                // TODO error handling
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    setQuery({ commit }, value) {
        const data = {
            ...value,
            // pageSize: value.itemsPerPage,
            // pageNumber: value.page,
        }
        commit('setQuery', _.cloneDeep(data))
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setData: set('data'),
    setPagination: set('pagination'),
    setQuery: set('query'),
    setLoading: set('loading'),
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
