var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.sectionsAndTasks.tasks,
          itemsPerPage: 1000,
          "hide-default-footer": "",
          "item-key": "id",
          "group-by": "SectionTitle",
          "fixed-header": "",
          "disable-sort": "",
          loading: _vm.loadingSectionsAndTasks,
        },
        scopedSlots: _vm._u([
          {
            key: "group.header",
            fn: function (ref) {
              var group = ref.group
              var headers = ref.headers
              var isOpen = ref.isOpen
              var toggle = ref.toggle
              return [
                _c(
                  "th",
                  { attrs: { colspan: headers.length } },
                  [
                    _c("v-icon", { on: { click: toggle } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(isOpen ? "mdi-minus" : "mdi-plus") +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "overline" }, [
                      _vm._v(_vm._s(group)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.inSchedule",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm.costUnits.find(function (costUnit) {
                  return costUnit.task && costUnit.task.TaskID == item.TaskID
                })
                  ? _c("v-icon", [
                      _vm._v("\n                mdi-chart-gantt\n            "),
                    ])
                  : _c("v-icon", { attrs: { color: "red lighten-4" } }, [
                      _vm._v(
                        "\n                mdi-circle-off-outline\n            "
                      ),
                    ]),
                _vm._v(" "),
                _vm.isReimbursable(item)
                  ? _c(
                      "v-chip",
                      { attrs: { color: "green lighten-4", small: "" } },
                      [_vm._v("R")]
                    )
                  : _c(
                      "v-chip",
                      { attrs: { color: "blue lighten-4", small: "" } },
                      [_vm._v("LS")]
                    ),
              ]
            },
          },
          {
            key: "item.Budget",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      item.EstimatedEffort
                        ? item.EstimatedEffort.toFixed(2)
                        : null
                    ) +
                    "\n        "
                ),
              ]
            },
          },
          {
            key: "item.Actuals",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(item.ActualTime ? item.ActualTime.toFixed(2) : 0.0) +
                    "\n        "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }