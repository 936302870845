import ProjectsIndex from '@/pages/projects/Index'
import ProjectsShow from '@/pages/projects/Show'

import ProjectsRatesIndex from '@/pages/projects/rates/Index'
import ProjectsTimesheetsIndex from '@/pages/projects/timesheets/Index'

import MaintenanceIndex from '@/pages/maintenance/Index'

import ProcurementIndex from '@/pages/procurement/Index'

import FinanceIndex from '@/pages/finance/Index'

import Alert from '@/components/Alert.vue'

const GlobalComponents = {
    install(Vue) {
        Vue.component('projects-index', ProjectsIndex)
        Vue.component('projects-show', ProjectsShow)

        Vue.component('projects-rates-index', ProjectsRatesIndex)
        Vue.component('projects-timesheets-index', ProjectsTimesheetsIndex)

        Vue.component('maintenance-index', MaintenanceIndex)
        Vue.component('procurement-index', ProcurementIndex)
        Vue.component('finance-index', FinanceIndex)

        Vue.component('alert', Alert)
    }
}

export default GlobalComponents
