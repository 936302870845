var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Alert"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-1 row-pointer",
        attrs: {
          headers: _vm.headers,
          items: _vm.data.Timesheets,
          "item-key": "id",
          "fixed-header": "",
          "show-select": "",
          dense: "",
          "sort-by": ["Lastname", "EntryDate", "StartTimeLocal"],
          "sort-desc": [true, false, true],
          "multi-sort": "",
          "group-by": "Lastname",
          "footer-props": {
            "items-per-page-options": [25, 50, 100, 200],
          },
          "items-per-page": 25,
          loading: _vm.loading,
        },
        on: { "click:row": _vm.showItem },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-toolbar-title", [_vm._v("Timesheets")]),
                    _vm._v(" "),
                    _c("v-spacer"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "pb-4 mx-1" },
                  [
                    _vm.avazaProjectIdsArr.length > 1
                      ? _c("v-switch", {
                          staticClass: "mx-4",
                          attrs: {
                            "true-value": _vm.avazaProjectIdsArr[1],
                            "false-value": _vm.avazaProjectIdsArr[0],
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "overline" }, [
                                      _vm._v("Reimbursable"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            886869118
                          ),
                          model: {
                            value: _vm.options.avazaProjectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "avazaProjectId", $$v)
                            },
                            expression: "options.avazaProjectId",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          "nudge-right": 40,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          label: "Entry date from",
                                          "prepend-icon": "mdi-calendar",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.options.entryDateFrom,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.options,
                                              "entryDateFrom",
                                              $$v
                                            )
                                          },
                                          expression: "options.entryDateFrom",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dateFromPicker,
                          callback: function ($$v) {
                            _vm.dateFromPicker = $$v
                          },
                          expression: "dateFromPicker",
                        },
                      },
                      [
                        _vm._v(" "),
                        _c("v-date-picker", {
                          attrs: { "first-day-of-week": _vm.firstDayOfWeek },
                          on: {
                            input: function ($event) {
                              _vm.dateFromPicker = false
                            },
                          },
                          model: {
                            value: _vm.options.entryDateFrom,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "entryDateFrom", $$v)
                            },
                            expression: "options.entryDateFrom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          "nudge-right": 40,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          label: "Entry date to",
                                          "prepend-icon": "mdi-calendar",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.options.entryDateTo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.options,
                                              "entryDateTo",
                                              $$v
                                            )
                                          },
                                          expression: "options.entryDateTo",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dateToPicker,
                          callback: function ($$v) {
                            _vm.dateToPicker = $$v
                          },
                          expression: "dateToPicker",
                        },
                      },
                      [
                        _vm._v(" "),
                        _c("v-date-picker", {
                          attrs: { "first-day-of-week": _vm.firstDayOfWeek },
                          on: {
                            input: function ($event) {
                              _vm.dateToPicker = false
                            },
                          },
                          model: {
                            value: _vm.options.entryDateTo,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "entryDateTo", $$v)
                            },
                            expression: "options.entryDateTo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "group.header",
            fn: function (ref) {
              var group = ref.group
              var headers = ref.headers
              var isOpen = ref.isOpen
              var toggle = ref.toggle
              var items = ref.items
              return [
                _c(
                  "th",
                  { attrs: { colspan: headers.length } },
                  [
                    _c("v-icon", { on: { click: toggle } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(isOpen ? "mdi-minus" : "mdi-plus") +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "overline" }, [
                      _vm._v(
                        _vm._s(items[0].Firstname) +
                          " " +
                          _vm._s(items[0].Lastname)
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.EntryDate",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v(_vm._s(_vm.dateFormat(item.EntryDate))),
                ]),
              ]
            },
          },
          {
            key: "item.Time",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-chip",
                      {
                        staticClass: "ma-1",
                        attrs: { label: "", color: "light-blue lighten-4" },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.timeFormat(item.StartTimeLocal)) +
                            " - " +
                            _vm._s(_vm.timeFormat(item.EndTimeLocal)) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.Duration",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-chip",
                      { staticClass: "ma-1", attrs: { outlined: "" } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.Duration.toFixed(2)) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.CategoryName",
            fn: function (ref) {
              var item = ref.item
              return [
                item.CategoryName
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-1",
                        attrs: { label: "", outlined: "" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.CategoryName) +
                            "\n            "
                        ),
                      ]
                    )
                  : _c(
                      "v-chip",
                      { staticClass: "ma-1", attrs: { label: "" } },
                      [_vm._v("\n                No Category\n            ")]
                    ),
              ]
            },
          },
          {
            key: "item.TimesheetEntryApprovalStatusCode",
            fn: function (ref) {
              var item = ref.item
              return [
                item.TimesheetEntryApprovalStatusCode === "Approved" ||
                item.TimesheetEntryApprovalStatusCode ===
                  "Automatically Approved"
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-1",
                        attrs: {
                          label: "",
                          color: "green",
                          "text-color": "white",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            item.TimesheetEntryApprovalStatusCode === "Approved"
                              ? "Approved"
                              : "Auto Approved"
                          ) + "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.TimesheetEntryApprovalStatusCode === "Draft"
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-1",
                        attrs: {
                          label: "",
                          color: "grey",
                          "text-color": "white",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(item.TimesheetEntryApprovalStatusCode) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.TimesheetEntryApprovalStatusCode === "Pending"
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-1",
                        attrs: {
                          label: "",
                          color: "amber",
                          "text-color": "white",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(item.TimesheetEntryApprovalStatusCode) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.TimesheetEntryApprovalStatusCode === "Rejected"
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-1",
                        attrs: {
                          label: "",
                          color: "red",
                          "text-color": "white",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(item.TimesheetEntryApprovalStatusCode) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.TimesheetEntryApprovalStatusCode === "Invoiced"
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-1",
                        attrs: {
                          label: "",
                          color: "light-blue",
                          "text-color": "white",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(item.TimesheetEntryApprovalStatusCode) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }