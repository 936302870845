var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.property.keyType
    ? _c("v-simple-table", {
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function () {
                return [
                  _c("thead", { staticClass: "overline" }, [
                    _c("td", [_vm._v("Existing Value")]),
                    _vm._v(" "),
                    _c("td", { staticStyle: { width: "20%" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.property.keyType) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.property.values, function (value, ind) {
                      return _c("tr", { key: ind }, [
                        _c("td", [_vm._v(_vm._s(value))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.property.keyType == "Sheet"
                                  ? _vm.getSheetName(ind)
                                  : _vm.property.keys[ind]
                              ) +
                              "\n                "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3292378752
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }