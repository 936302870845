var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-banner",
        {
          staticClass: "mb-2",
          attrs: { "single-line": "" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _vm.addNewColumn || _vm.selectedColumns.length
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            text: "",
                            loading: _vm.updatingColumns,
                          },
                          on: { click: _vm.addOrUpdateColumns },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.setorModify) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v("\n        Column Update\n\n        ")]
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "v-alert",
            { attrs: { border: "top", color: "red lighten-2", dark: "" } },
            [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("div", { staticClass: "overline" }, [
            _vm._v("Selected sheets "),
            _c("span", { staticClass: "pr-2 caption" }, [
              _vm._v(_vm._s(_vm.selectedSheetsNames)),
            ]),
          ]),
          _vm._v(" "),
          _vm.loadingProperties
            ? _c("v-progress-linear", {
                attrs: { indeterminate: "", color: "primary" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("Column select")]),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-switch", {
                attrs: { disabled: "" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "overline" }, [
                          _vm._v("New column"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.addNewColumn,
                  callback: function ($$v) {
                    _vm.addNewColumn = $$v
                  },
                  expression: "addNewColumn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              !_vm.addNewColumn
                ? _c("v-select", {
                    attrs: {
                      items: _vm.availableColumns,
                      "menu-props": { maxHeight: "400" },
                      label: "Existing columns",
                      multiple: "",
                      hint:
                        "Columns listed are only those common to the selected sheets.",
                      "no-data-text":
                        "No common columns found for the selected Smartsheets",
                      "persistent-hint": "",
                      "small-chips": "",
                      "deletable-chips": "",
                    },
                    model: {
                      value: _vm.selectedColumns,
                      callback: function ($$v) {
                        _vm.selectedColumns = $$v
                      },
                      expression: "selectedColumns",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.cantShowMeaningfulExistingProps
        ? _c("ShowProperties", { attrs: { name: "primary" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.addNewColumn || _vm.selectedColumns.length == 1
        ? _c(
            "div",
            [
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("v-switch", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "overline" }, [
                                    _vm._v(_vm._s(_vm.setorModify) + " title"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3116174392
                        ),
                        model: {
                          value: _vm.setTitle,
                          callback: function ($$v) {
                            _vm.setTitle = $$v
                          },
                          expression: "setTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "9" } },
                    [
                      _vm.setTitle
                        ? _c("v-text-field", {
                            attrs: { label: "Title" },
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title = $$v
                              },
                              expression: "title",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.addNewColumn || _vm.selectedColumns.length
        ? _c(
            "div",
            [
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("v-switch", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "overline" }, [
                                    _vm._v(
                                      _vm._s(_vm.setorModify) + " description"
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2821879582
                        ),
                        model: {
                          value: _vm.setDescription,
                          callback: function ($$v) {
                            _vm.setDescription = $$v
                          },
                          expression: "setDescription",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "9" } },
                    [
                      _vm.setDescription
                        ? _c("v-text-field", {
                            attrs: {
                              label: "Description",
                              "persistent-hint": "",
                              hint:
                                "Leave blank to clear the existing description",
                            },
                            model: {
                              value: _vm.description,
                              callback: function ($$v) {
                                _vm.description = $$v
                              },
                              expression: "description",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.cantShowMeaningfulExistingProps
                ? _c("ShowProperties", { attrs: { name: "description" } })
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-radio-group",
                {
                  attrs: { row: "", mandatory: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "overline" }, [
                              _vm._v("Visibility"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    158055671
                  ),
                  model: {
                    value: _vm.hidden,
                    callback: function ($$v) {
                      _vm.hidden = $$v
                    },
                    expression: "hidden",
                  },
                },
                [
                  _vm._v(" "),
                  !_vm.addNewColumn
                    ? _c("v-radio", {
                        attrs: { value: "unchanged" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "caption" }, [
                                    _vm._v("Unchanged"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1143909222
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-radio", { attrs: { label: "Hidden", value: true } }),
                  _vm._v(" "),
                  _c("v-radio", { attrs: { label: "Visible", value: false } }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.cantShowMeaningfulExistingProps
                ? _c("ShowProperties", { attrs: { name: "hidden" } })
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              !_vm.setColumnFormula
                ? _c(
                    "v-radio-group",
                    {
                      attrs: { row: "", mandatory: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "overline" }, [
                                  _vm._v("Sub-Admin access"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3547194099
                      ),
                      model: {
                        value: _vm.locked,
                        callback: function ($$v) {
                          _vm.locked = $$v
                        },
                        expression: "locked",
                      },
                    },
                    [
                      _vm._v(" "),
                      !_vm.addNewColumn
                        ? _c("v-radio", {
                            attrs: { value: "unchanged" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", { staticClass: "caption" }, [
                                        _vm._v("Unchanged"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1143909222
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-radio", {
                        attrs: { label: "Locked", value: true },
                      }),
                      _vm._v(" "),
                      _c("v-radio", {
                        attrs: { label: "Unlocked", value: false },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.cantShowMeaningfulExistingProps
                ? _c("ShowProperties", { attrs: { name: "locked" } })
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("v-switch", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "overline" }, [
                                    _vm._v(_vm._s(_vm.setorModify) + " width"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2468582590
                        ),
                        model: {
                          value: _vm.setWidth,
                          callback: function ($$v) {
                            _vm.setWidth = $$v
                          },
                          expression: "setWidth",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "9" } },
                    [
                      _vm.setWidth
                        ? _c("v-text-field", {
                            attrs: { label: "Width" },
                            model: {
                              value: _vm.width,
                              callback: function ($$v) {
                                _vm.width = $$v
                              },
                              expression: "width",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.cantShowMeaningfulExistingProps
                ? _c("ShowProperties", { attrs: { name: "width" } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    attrs: {
                      disabled: !(
                        _vm.addNewColumn || _vm.selectedColumns.length == 1
                      ),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "overline" }, [
                              _vm._v(
                                _vm._s(_vm.setorModify) +
                                  " column-level formula"
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.setColumnFormula,
                      callback: function ($$v) {
                        _vm.setColumnFormula = $$v
                      },
                      expression: "setColumnFormula",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.setColumnFormula
                    ? _c("v-textarea", {
                        staticClass: "caption",
                        attrs: {
                          name: "columnFormula",
                          label: "Formula",
                          "persistent-hint": "",
                          hint:
                            "Must start with an equal sign. First ensure the formula is correct in a test Smartsheet.",
                        },
                        model: {
                          value: _vm.columnFormula,
                          callback: function ($$v) {
                            _vm.columnFormula = $$v
                          },
                          expression: "columnFormula",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.cantShowMeaningfulExistingProps
            ? _c("ShowProperties", { attrs: { name: "formula" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }