<template>
    <div>
        <CostUnitSummary />

        <v-tabs v-model='tab' grow>
            <v-tab key='cost_units'>Cost Units</v-tab>
            <v-tab key='extra_avaza' v-if='extraneousAvazaTasks.length'>Extra Avaza Tasks</v-tab>
        </v-tabs>
        <v-tabs-items v-model='tab'>
            <v-tab-item flat key='cost_units' :eager='true'>
                <v-data-table
                    :headers='headers'
                    :items='scheduleCostUnits'
                    :itemsPerPage='1000'
                    :item-class='itemRowBackground'
                    hide-default-footer
                    item-key='id'
                    group-by='avaza_section'
                    sort-by='cost_code'
                    disable-sort
                    class='elevation-1'
                    fixed-header
                    :loading='loadingAnything'
                    loading-text='Loading schedule cost units...'
                >
                    <template v-slot:group.header='{ group, headers, isOpen, toggle }'>
                        <th :colspan='headers.length'>
                        <v-icon @click='toggle'>
                            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                        </v-icon>
                        <span class='overline'>{{ group }}</span>
                        </th>
                    </template>

                    <template v-slot:item.icons='{ item }'>
                        <v-chip
                            v-if='entry.commercial_type == "Lump Sum + Rates"'
                            :color='item.type == "lumpSum"
                                ? "orange lighten-4"
                                : "green lighten-4"
                            '
                            small
                        >{{ item.type.substring(0, 1).toUpperCase() }}</v-chip>
                    </template>

                    <template v-slot:item.activity_id='{ item }'>
                        <v-chip-group column><v-chip
                            class='pa-1 flex-grow-1 justify-center'
                            color='blue-grey'
                            small label outlined
                        >
                            {{ item.activity_id }}
                        </v-chip></v-chip-group>
                    </template>

                    <template v-slot:item.activity='{ item }'>
                        <span v-if='!item.avaza || (item.cost_code + " " + item.activity == item.avaza.name)'
                            class="caption py-1" style='line-height: 100%'
                        >
                            {{ item.activity }}
                        </span>
                        <v-tooltip v-else bottom>
                            <template v-slot:activator='{ on, attrs }'>
                                <span v-on='on' class='caption py-1' style='line-height: 100%'>
                                    {{ item.activity }} <span class='red--text'>*</span>
                                </span>
                            </template>
                            <span>The Avaza name for this Cost Unit differs. The Avaza task name is "{{ item.avaza.name }}".</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.budget_hours='{ item }'>
                        <span v-if='!item.avaza || (item.budget_hours == item.avaza.budget_hours)'>
                            {{ item.budget_hours.toFixed(1) }}
                        </span>
                        <v-tooltip v-else bottom>
                            <template v-slot:activator='{ on, attrs }'>
                                <span v-on='on' class='red--text'>
                                    {{ item.budget_hours.toFixed(1) }}
                                </span>
                            </template>
                            <span>The Avaza budget for this Cost Unit differs. The Avaza task budget is {{ item.avaza.budget_hours.toFixed(1) }}.</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.actual_hours='{ item }'>
                        <span v-if='!item.hasOwnProperty("avaza") || Math.abs(item.actual_hours - item.avaza.recorded_hours) < 0.001'>
                                {{ item.actual_hours ? item.actual_hours.toFixed(1) : null }}
                        </span>

                        <v-tooltip v-else bottom>
                            <template v-slot:activator='{ on, attrs }'>
                                <v-badge color='orange lighten-1' dot>
                                    <span v-on='on'>
                                        {{ item.actual_hours ? item.actual_hours.toFixed(1) : null }}
                                    </span>
                                </v-badge>
                            </template>
                            <span>There is additional time <strong>recorded</strong> in Avaza. This may be unapproved, or beyond the end of the last period.<br>This totals {{ (item.avaza.recorded_hours - item.actual_hours).toFixed(1) }} hours.</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.budgetRemHrs='{ item }'>
                        <span
                            :class='
                                (item.budget_hours - item.actual_hours) < 0
                                ? "red--text lighten-1"
                                : "green--text lighten-1 "
                            '
                        >
                            {{ (item.budget_hours - item.actual_hours).toFixed(1) }}
                        </span>
                    </template>

                    <template v-slot:item.complete_pc='{ item }'>
                        {{ (item['complete_%'] * 100).toFixed(0) }}%
                    </template>

                    <template v-slot:item.unsyncedApproved='{ item }'>
                        <CostUnitAvaza v-if='item.avaza' :item='item' timeType='unsyncedApproved' />
                    </template>

                    <template v-slot:item.futureApproved='{ item }'>
                        <CostUnitAvaza v-if='item.avaza' :item='item' timeType='futureApproved' />
                    </template>

                    <template v-slot:item.pendingOrDraft='{ item }'>
                        <CostUnitAvaza v-if='item.avaza' :item='item' timeType='pendingOrDraft' />
                    </template>

                    <template v-slot:item.rejected='{ item }'>
                        <CostUnitAvaza v-if='item.avaza' :item='item' timeType='rejected' />
                    </template>

                    <template v-slot:item.avazaTask='{ item }'>
                        <CostUnitMappedTask v-if='item.avaza' :item='item' />
                        <CostUnitCreateTask v-else :item='item' />
                    </template>

                </v-data-table>
            </v-tab-item>
            <v-tab-item flat key='extra_avaza'>
                <v-simple-table>
                    <template v-slot:default>
                        <thead><tr>
                            <th class="text-left"></th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Budget</th>
                            <th class="text-left">
                                <v-tooltip bottom>
                                    <template v-slot:activator='{ on }'>
                                        <span v-on='on' style='line-height: 100%'>
                                            Recorded Time (hrs)
                                        </span>
                                    </template>
                                    <span>Includes all time regardless of approval status and time period.</span>
                                </v-tooltip>
                            </th>
                        </tr></thead>
                        <tbody><tr
                            v-for="item in extraneousAvazaTasks"
                            :key="item.name"
                        >
                            <td>
                                <v-btn
                                    text x-small color='green accent-4'
                                    :href='`https://gtegroup.avaza.com/project/view/${
                                                item.type ? entry[`avaza_${item.type.toLowerCase()}_project_id`] : entry.avaza_lumpsum_project_id
                                            }#!tab=task-pane&groupby=ProjectSection&view=vertical&task=${item.id}&fileview=grid`'
                                    target='_blank'
                                >
                                    Task {{ item.id }}
                                </v-btn>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.budget_hours }}</td>
                            <td>{{ item.recorded_hours }}</td>
                        </tr></tbody>
                    </template>
                </v-simple-table>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CostUnitAvaza from '@/components/projects/CostUnitAvaza';
import CostUnitCreateTask from '@/components/projects/CostUnitCreateTask';
import CostUnitMappedTask from '@/components/projects/CostUnitMappedTask';
import CostUnitSummary from '@/components/projects/CostUnitSummary';

export default {
    components: {
        CostUnitAvaza,
        CostUnitCreateTask,
        CostUnitMappedTask,
        CostUnitSummary,
    },
    data() {
        return {
            tab: 0,
            headers: [
                { text: '', value: 'isReimbursable' },
                { text: 'Activity ID', value: 'activity_id' },
                { text: '', value: 'icons' },
                { text: 'Cost Code', value: 'cost_code' },
                { text: 'Activity Name', value: 'activity' },
                { text: '% Complete', value: 'complete_pc', align: 'right' },
                { text: 'Budget (hrs)', value: 'budget_hours', align: 'right' },
                { text: 'Actual (hrs) on Schedule', value: 'actual_hours', align: 'right' },
                { text: 'Budget Rem. (hrs)', value: 'budgetRemHrs', align: 'right' },
                { text: 'Unsynced Approved (hrs)', value: 'unsyncedApproved', align: 'right' },
                { text: 'Future Approved (hrs)', value: 'futureApproved', align: 'right' },
                { text: 'Draft & Pending (hrs)', value: 'pendingOrDraft', align: 'right' },
                { text: 'Rejected (hrs)', value: 'rejected', align: 'right' },
                { text: 'Avaza Task', value: 'avazaTask', width: '150px', align: 'center'  },
            ],
        };
    },
    watch: {
        'entry.commercial_type' () {
            if( this.entry.commercial_type ) {
                this.fetchScheduleCostUnits()
                this.fetchAvazaCostUnits()
                this.fetchAvazaTimesheets()
            }
        }
    },
    computed: {
         ...mapGetters('ProjectsSingle', [
            'entry',
            'loading',
        ]),
        ...mapGetters('CostUnits', [
            'loadingAnything',
            'scheduleCostUnits',
            'extraneousAvazaTasks',
        ]),
    },
    methods: {
        ...mapActions('CostUnits', [
            'fetchScheduleCostUnits',
            'fetchAvazaCostUnits',
            'fetchAvazaTimesheets',
        ]),
        itemRowBackground: function (item) {
            return item['complete_%'] == 1 ? 'light-green lighten-5' : null
        }
    },
}
</script>

