<template>
        <v-app>
        <Alert />

        <v-card
            outlined
            class="mb-2"
        >
            <v-card-title class="py-0">
                <span class="overline">Finance</span>
            </v-card-title>

            <v-card-text>

                <v-card-title>Extract Xero Data to Smartsheet</v-card-title>

                <v-row>

                    <v-col>
                        <v-date-picker
                            v-model="dataMonth"
                            type="month"
                            landscape
                            :max="maxMonth"
                        ></v-date-picker>
                    </v-col>

                    <v-col>
                        <v-row><v-btn
                            color="primary"
                            text
                            @click="extract('all')"
                            :disabled="!dataMonth || extractAllDisabled"
                            block
                        >
                            Extract All
                        </v-btn></v-row>
                        <v-row><v-btn
                            color="secondary"
                            text
                            @click="extract('sales-labour-by-project')"
                            :disabled="!dataMonth"
                            :loading="salesLabourByProject"
                            small block
                        >
                            Sales - Labour by Project
                        </v-btn></v-row>
                        <v-row><v-btn
                            color="secondary"
                            text
                            @click="extract('sales-equipment-by-project')"
                            :disabled="!dataMonth"
                            :loading="salesEquipmentByProject"
                            small block
                        >
                            Sales - Equipment by Project
                        </v-btn></v-row>
                        <v-row><v-btn
                            color="secondary"
                            text
                            @click="extract('sales-other-by-project')"
                            :disabled="!dataMonth"
                            :loading="salesOtherByProject"
                            small block
                        >
                            Sales - Other by Project
                        </v-btn></v-row>
                        <v-row><v-btn
                            color="secondary"
                            text
                            @click="extract('cos-labour-by-project')"
                            :disabled="!dataMonth"
                            :loading="cosLabourByProject"
                            small block
                        >
                            COS - Labour by Project
                        </v-btn></v-row>
                        <v-row><v-btn
                            color="secondary"
                            text
                            @click="extract('cos-equipment-by-project')"
                            :disabled="!dataMonth"
                            :loading="cosEquipmentByProject"
                            small block
                        >
                            COS - Equipment by Project
                        </v-btn></v-row>
                        <v-row><v-btn
                            color="secondary"
                            text
                            @click="extract('cos-other-by-project')"
                            :disabled="!dataMonth"
                            :loading="cosOtherByProject"
                            small block
                        >
                            COS - Other by Project
                        </v-btn></v-row>

                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-card-title>Download Xero Attachments</v-card-title>

                <v-row>
                    <v-col>
                        <v-row><v-text-field
                            v-model="gteProjectId"
                            label="Project ID"
                            persistent-hint
                            hint="Excluding the 'P'. 4-digit number only."
                            class="caption"
                        ></v-text-field>
                        </v-row>
                        <v-row>&nbsp;</v-row>
                        <v-row><v-date-picker
                            v-model="dataRange"
                            range
                            landscape
                            :max="maxDate"
                            first-day-of-week=6
                        ></v-date-picker></v-row>
                    </v-col>

                    <v-col>
                        <v-row><v-btn
                            color="primary"
                            text
                            @click="download"
                            :disabled="!dataRange || downloadDisabled"
                            block
                        >
                            Download
                        </v-btn></v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import ApiService from "@/services/api.service"

export default {
    data() {
        return {
            extractAllDisabled: false,
            downloadDisabled: false,
            salesLabourByProject: false,
            salesEquipmentByProject: false,
            salesOtherByProject: false,
            cosLabourByProject: false,
            cosEquipmentByProject: false,
            cosOtherByProject: false,
            gteProjectId: '',
            dataRange: ['', ''],
            dataMonth: new Date().toISOString().substring(0,7),
            maxMonth: new Date().toISOString().substring(0,7),
            maxDate: new Date().toISOString(),
        };
    },
    methods: {
        extract(dataType) {
            this.extractAllDisabled = true
            const dT = dataType.split("-")
                        .map((w, index) => index === 0 ? w : w.charAt(0).toUpperCase() + w.slice(1))
                        .join("")

            this[dT] = true

            ApiService
                .get(`xero/extract/${dataType}/${this.dataMonth}`)
                .then(response => {
                    console.log(response.data) // for debug only
                })
                .catch(error => console.log(error.response.data))
                .finally(() => {
                    this[dT] = false
                    this.extractAllDisabled = false
                });
        },
        download() {
            this.downloadDisabled = true

            ApiService
                .post(`xero/download/`, {
                    gteProjectId: this.gteProjectId,
                    startDate: this.dataRange[0],
                    endDate: this.dataRange[1]
                })
                .then(response => {
                    console.log(response.data) // for debug only
                })
                .catch(error => console.log(error.response.data))
                .finally(() => {
                    this.downloadDisabled = false
                });
        }
    }
};
</script>
