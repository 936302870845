import ApiService from "@/services/api.service";

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        projects: {
            lumpSum: [],
            reimbursable: [],
        },
        sectionsAndTasks: {
            sections: [],
            tasks: [],
        },
        avazaSummary: {
            lumpSum: [],
            reimbursable: [],
        },
        loading: false,
        loadingSectionsAndTasks: false,
        loadingProjectOptions: false,
        errors: [],
    }
}

const getters = {
    projects: (state) => state.projects,
    lumpSumProject: (state) => state.projects.lumpSum,
    reimbursableProject: (state) => state.projects.reimbursable,
    avazaSummary: (state) => state.avazaSummary,
    sectionsAndTasks: (state) => state.sectionsAndTasks,
    lumpSumSectionsAndTasks: (state) => state.lumpSumSectionsAndTasks, // DEPRECATE
    reimbursableSectionsAndTasks: (state) => state.reimbursableSectionsAndTasks, // DEPRECATE
    loading: (state) => state.loading,
    loadingSectionsAndTasks: (state) => state.loadingSectionsAndTasks,
}

const actions = {
    async createSection({ commit }, data) {
        return new Promise((resolve, reject) => {

            ApiService
            .post(`avaza/projects/${data.ProjectIDFK}/sections`, { params: data } )
                .then(response => {
                    // console.log(response.data)
                    resolve({
                        title: data.Title,
                        sectionId: response.data.sectionId,
                    })
                })
                .catch(error => {
                    console.log(error.response) // don't comment this out
                    commit('appendToErrors', "Failed to create Avaza section (refer console).")
                    reject(error)
                })

        })
    },
    async createTask({ commit }, data) {
        return new Promise((resolve, reject) => {

            const params = {
                ...data.taskObject
            }

            if (params.EstimatedEffort > 0 || data.hasOwnProperty('skipBudgetConstraint')) {
                ApiService
                    .post(`avaza/projects/${data.taskObject.ProjectIDFK}/tasks`, { params } )
                    .then(response => {
                        // console.log(response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.data)
                        commit('appendToErrors', "Failed to create Avaza section (refer console).")
                        reject(error)
                    })
            } else {
                console.log("Task skipped as Budget (hrs) is zero.")
                console.log(params)
                resolve("Skipped")
            }

        })
    },
    async fetchAvazaSectionsAndTasks({ state, commit, dispatch, rootGetters }) {

        commit('setLoadingSectionsAndTasks', true)

        const avazaProjectsIds = {
            lumpSum: rootGetters['ProjectsSingle/entry'].avaza_lumpsum_project_id ?? null,
            reimbursable: rootGetters['ProjectsSingle/entry'].avaza_reimbursable_project_id ?? null,
        }

        let sectionsAndTasks = {
            sections: [],
            tasks: []
        }

        for (let key in avazaProjectsIds) {
            const project_id = avazaProjectsIds[key]

            if (project_id) {

                await ApiService
                    .get(`avaza/projects/${project_id}/sectionsAndTasks`)
                    .then(response => {
                        sectionsAndTasks.sections.push(...response.data.sections)
                        sectionsAndTasks.tasks.push(...response.data.tasks)
                    })
                    .catch((error) => {
                        console.log(error.response) // comment out once confirmed Alert shows the error
                        dispatch(
                            "Alert/setAlert",
                            { message: "Error loading Avaza Sections and Tasks", errors: [error.response], type: "error" },
                            { root: true }
                        )

                    })
            }
        }
        commit('setSectionsAndTasks', sectionsAndTasks)

        const tasksLumpSum = state.sectionsAndTasks?.tasks.filter(task => {
            return task.ProjectIDFK === avazaProjectsIds.lumpSum
        }) ?? []
        const tasksReimbursable = state.sectionsAndTasks?.tasks.filter(task => {
            return task.ProjectIDFK === avazaProjectsIds.reimbursable
        }) ?? []

        commit('setAvazaSummary', {
            lumpSum: {
                totalTasks:         tasksLumpSum.length ?? 0,

                totalActualHours:   tasksLumpSum.reduce((currentTotal, task) => {
                                        return task.ActualTime + currentTotal
                                    }, 0) ?? 0,

                totalBudgetHours:   tasksLumpSum.reduce((currentTotal, task) => {
                                        return task.EstimatedEffort + currentTotal
                                    }, 0) ?? 0,
            },
            reimbursable: {
                totalTasks:         tasksReimbursable.length ?? 0,

                totalActualHours:   tasksReimbursable.reduce((currentTotal, task) => {
                                        return task.ActualTime + currentTotal
                                    }, 0) ?? 0,

                totalBudgetHours:   tasksReimbursable.reduce((currentTotal, task) => {
                                        return task.EstimatedEffort + currentTotal
                                    }, 0) ?? 0,
            }
        })

        dispatch('Schedule/updateCostUnitsForAvaza', true, { root: true })

        commit('setLoadingSectionsAndTasks', false)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setLoading: set('loading'),
    setSectionsAndTasks: set('sectionsAndTasks'),
    setLoadingSectionsAndTasks: set('loadingSectionsAndTasks'),
    setAvazaSummary: set('avazaSummary'),
    appendToErrors(state, payload) {
        state.errors.push(payload)
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
