var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto mb-5 elevation-1" },
    [
      _c(
        "v-card-title",
        { staticClass: "overline" },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.type == "reimbursable" ? "Reimbursable" : "Lump Sum") +
              "\n\n        "
          ),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.entry.last_synced
            ? _c("span", { staticClass: "caption" }, [
                _vm._v("Last synced: " + _vm._s(_vm.entry.last_synced)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.costUnitSummary[_vm.type].hasOwnProperty("totalWithTask") &&
          _vm.avazaSummary[_vm.type].hasOwnProperty("totalActualHours")
            ? _c(
                "v-card-actions",
                [
                  _vm.costUnitSummary[_vm.type].totalWithTask ==
                    _vm.costUnitSummary[_vm.type].totalCostUnits || true //temp disabled
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            disabled:
                              _vm.avazaSummary[
                                _vm.type
                              ].totalActualHours.toFixed(2) ==
                              _vm.costUnitSummary[
                                _vm.type
                              ].totalActualHours.toFixed(2),
                            loading: _vm.syncing,
                            light: "",
                            text: "",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.syncActuals(_vm.type)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Sync Schedule & Avaza\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", [_vm._v("Budget (hrs)")]),
                _vm._v(" "),
                _c("p", { staticClass: "display-1 text--primary" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.costUnitSummary[_vm.type].totalBudgetHours) +
                      "\n                    "
                  ),
                  !_vm.loadingSectionsAndTasks
                    ? _c("span", { staticClass: "caption" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.avazaSummary[_vm.type].totalBudgetHours
                            ) +
                            " in Avaza\n                    "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.costUnitSummary[_vm.type].totalBudgetHours > 0 &&
                !_vm.loadingSectionsAndTasks
                  ? _c("div", { staticClass: "overline" }, [
                      _vm.avazaSummary[_vm.type].totalBudgetHours ==
                      _vm.costUnitSummary[_vm.type].totalBudgetHours
                        ? _c("span", [
                            _vm._v(
                              "\n                        Budgets in sync\n                    "
                            ),
                          ])
                        : _c("span", { staticClass: "orange--text" }, [
                            _vm._v(
                              "\n                        Budget discrepancy: " +
                                _vm._s(
                                  _vm.avazaSummary[_vm.type].totalBudgetHours -
                                    _vm.costUnitSummary[_vm.type]
                                      .totalBudgetHours
                                ) +
                                "\n                    "
                            ),
                          ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("v-col", [
                _c("div", [_vm._v("Actuals (hrs)")]),
                _vm._v(" "),
                _c("p", { staticClass: "display-1 text--primary" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.costUnitSummary[_vm.type].totalActualHours.toFixed(
                          2
                        )
                      ) +
                      "\n                    "
                  ),
                  !_vm.loadingSectionsAndTasks
                    ? _c("span", { staticClass: "caption" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.avazaSummary[
                                _vm.type
                              ].totalActualHours.toFixed(2)
                            ) +
                            " in Avaza\n                    "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.costUnitSummary[_vm.type].totalBudgetHours > 0 &&
                !_vm.loadingSectionsAndTasks
                  ? _c("div", { staticClass: "overline" }, [
                      _vm.avazaSummary[_vm.type].totalActualHours.toFixed(2) ==
                      _vm.costUnitSummary[_vm.type].totalActualHours.toFixed(2)
                        ? _c("span", [
                            _vm._v(
                              "\n                        Actuals in sync\n                    "
                            ),
                          ])
                        : _c("span", { staticClass: "orange--text" }, [
                            _vm._v(
                              "\n                        Actuals discrepancy: " +
                                _vm._s(
                                  (
                                    _vm.avazaSummary[_vm.type]
                                      .totalActualHours -
                                    _vm.costUnitSummary[_vm.type]
                                      .totalActualHours
                                  ).toFixed(2)
                                ) +
                                "\n                    "
                            ),
                          ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("v-col", [
                _c("div", [_vm._v("#")]),
                _vm._v(" "),
                _c("p", { staticClass: "display-1 text--primary" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.costUnitSummary[_vm.type].totalCostUnits) +
                      "\n                    "
                  ),
                  !_vm.loadingSectionsAndTasks
                    ? _c("span", { staticClass: "caption" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.costUnitSummary[_vm.type].totalWithTask
                            ) +
                            " in Avaza\n                        "
                        ),
                        _vm.avazaSummary[_vm.type].totalTasks !=
                        _vm.costUnitSummary[_vm.type].totalWithTask
                          ? _c("span", [
                              _vm._v(
                                "\n                            (+ " +
                                  _vm._s(
                                    _vm.avazaSummary[_vm.type].totalTasks -
                                      _vm.costUnitSummary[_vm.type]
                                        .totalWithTask
                                  ) +
                                  " others)\n                        "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.costUnitSummary[_vm.type].totalBudgetHours > 0 &&
                !_vm.loadingSectionsAndTasks
                  ? _c("div", { staticClass: "overline" }, [
                      _vm.costUnitSummary[_vm.type].totalWithTask ==
                      _vm.costUnitSummary[_vm.type].totalCostUnits
                        ? _c("span", [
                            _vm._v(
                              "\n                        Tasks aligned\n                    "
                            ),
                          ])
                        : _c("span", { staticClass: "red--text" }, [
                            _vm._v(
                              "\n                        Avaza is missing tasks\n                    "
                            ),
                          ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }