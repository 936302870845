import ApiService from "@/services/api.service";

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        sheets: [],
        pagination: 0,
        query: {},

        schemas: [],
        loadingSchemas: false,

        cachingBatch: [],

        batching: false,

        selectedSheets: [],
    }
}

const getters = {
    sheets: (state) => state.sheets,
    batching: (state) => state.batching,
    cachingBatch: (state) => state.cachingBatch,
    schemas: (state) => state.schemas,
    loadingSchemas: (state) => state.loadingSchemas,
    selectedSheets: (state) => state.selectedSheets,
    pagination: (state) => state.pagination,
}

const actions = {
    fetchSmartsheetsCache({ commit, state }) {
        ApiService
        .get(`caches`, { params: state.query } )
        .then(response => {
            // console.log(response.data)
            commit('setSheets', response.data.data)
            commit('setPagination', {
                'current_page': response.data.current_page,
                'per_page': response.data.per_page,
                'total': response.data.total
            })
        })
    },
    updateSmartsheetsCache({ commit }) {
        commit('setBatching', true)

        ApiService
            .post(`caches`)
            .then(response => {
                commit('setCachingBatch', response.data)
            })
            .finally(() => {
                commit('setBatching', false)
            });
    },
    fetchSchemas({ commit }) {
        commit('setLoadingSchema', true)

        ApiService
        .get(`schemas`)
        .then(response => {
            commit('setSchema', response.data)
        })
        .finally(() => {
            commit('setLoadingSchema', false)
        });
    },
    fetchCachingStatus({ commit, state }) {
        ApiService
            .get(`batches/${state.cachingBatch.id ?? null}`)
            .then(response => {
                commit('setCachingBatch', response.data)
            })
    },
    setQuery({ commit }, value) {
        commit('setQuery', _.cloneDeep(value))
    },
    setSelectedSheets({ commit }, value) {
        // const x = value.map(item => item.smartsheet_id)
        commit('setSelectedSheets', _.cloneDeep(value))
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setSheets: set('sheets'),

    setSchema: set('schemas'),
    setLoadingSchema: set('loadingSchemas'),

    setCachingBatch: set('cachingBatch'),
    setBatching: set('batching'),

    setPagination: set('pagination'),
    setQuery: set('query'),

    setSelectedSheets: set('selectedSheets'),

    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
