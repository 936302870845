<template>
    <v-card v-if='!loadingAnything' class='mx-auto mb-10 elevation-1'>
        <v-card-title class='overline'>
            <span v-if='entry.last_synced' class='caption'>Last synced: {{ entry.last_synced }}</span>
            <v-spacer />
            <v-card-actions v-if='true'>
                <v-btn
                    :disabled='loadingAnything'
                    @click='hideScheduleColumns()'
                    :loading='syncing'
                    color="secondary"
                    light text
                    target="_blank"
                >
                    Hide Non Standard Columns
                    <v-icon right>mdi-autorenew</v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-actions v-if='true'>
                <v-btn
                    :disabled='loadingAnything'
                    @click='syncActuals(type)'
                    :loading='syncing'
                    color="secondary"
                    light text
                    target="_blank"
                >
                    Sync
                    <v-icon right>mdi-autorenew</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card-title>

        <v-card-text>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead><tr>
                        <th></th>
                        <th class='text-right'>Budget (hrs)</th>
                        <th class='text-right'>Actual (hrs) on Schedule</th>
                        <th class='text-right'>Budget Remaining (hrs)</th>
                        <th class='text-right'>Unsynced Approved (hrs)</th>
                        <th class='text-right'>Future Period Approved (hrs)</th>
                        <th class='text-right'>Pending or Draft (hrs)</th>
                        <th class='text-right'>Rejected (hrs)</th>

                    </tr></thead>
                    <tbody>
                        <tr v-if='hasReimbursable && hasLumpSum'>
                            <td>Reimbursable</td>
                            <td class='text-right'>{{ totals.reimbursable.schBudgetTotalHrs }}</td>
                            <td class='text-right'>{{ totals.reimbursable.schActualTotalHrs }}</td>
                            <td class='text-right'>
                                <span
                                    :class='
                                        totals.reimbursable.BudgetRemTotalHrs < 0
                                        ? "red--text lighten-1"
                                        : "green--text lighten-1 "
                                    '
                                >
                                    {{ totals.reimbursable.BudgetRemTotalHrs }}
                                </span>
                            </td>
                            <td class='text-right'>
                                <span
                                    v-if='Math.abs(totals.reimbursable.avzUnsyncedAppTotalHrs) > 0.01'
                                    class='red--text lighten-1'
                                >
                                    {{ totals.reimbursable.avzUnsyncedAppTotalHrs }}
                                </span>
                                <span v-else>0.0</span>
                            </td>
                            <td class='text-right'>{{ totals.reimbursable.avzFutureAppTotalHrs }}</td>
                            <td class='text-right'>{{ totals.reimbursable.avzPendingTotalHrs }}</td>
                            <td class='text-right'>{{ totals.reimbursable.avzRejectedTotalHrs }}</td>
                        </tr>
                        <tr v-if='hasReimbursable && hasLumpSum'>
                            <td>Lump Sum</td>
                            <td class='text-right'>{{ totals.lumpSum.schBudgetTotalHrs }}</td>
                            <td class='text-right'>{{ totals.lumpSum.schActualTotalHrs }}</td>
                            <td class='text-right'>
                                <span
                                    :class='
                                        totals.lumpSum.BudgetRemTotalHrs < 0
                                        ? "red--text lighten-1"
                                        : "green--text lighten-1 "
                                    '
                                >
                                    {{ totals.lumpSum.BudgetRemTotalHrs }}
                                </span>
                            </td>
                            <td class='text-right'>
                                <span
                                    v-if='Math.abs(totals.lumpSum.avzUnsyncedAppTotalHrs) > 0.01'
                                    class='red--text lighten-1'
                                >
                                    {{ totals.lumpSum.avzUnsyncedAppTotalHrs }}
                                </span>
                                <span v-else>0.0</span>
                            </td>
                            <td class='text-right'>{{ totals.lumpSum.avzFutureAppTotalHrs }}</td>
                            <td class='text-right'>{{ totals.lumpSum.avzPendingTotalHrs }}</td>
                            <td class='text-right'>{{ totals.lumpSum.avzRejectedTotalHrs }}</td>
                        </tr>
                        <tr>
                            <td>TOTAL</td>
                            <td class='text-right'>{{ totals.combined.schBudgetTotalHrs }}</td>
                            <td class='text-right'>{{ totals.combined.schActualTotalHrs }}</td>
                            <td class='text-right'>
                                <span
                                    :class='
                                        totals.combined.BudgetRemTotalHrs < 0
                                        ? "red--text lighten-1"
                                        : "green--text lighten-1 "
                                    '
                                >
                                    {{ totals.combined.BudgetRemTotalHrs }}
                                </span>
                            </td>
                            <td class='text-right'>
                                <span
                                    v-if='Math.abs(totals.combined.avzUnsyncedAppTotalHrs) > 0.01'
                                    class='red--text lighten-1'
                                >
                                    {{ totals.combined.avzUnsyncedAppTotalHrs }}
                                </span>
                                <span v-else>0.0</span>
                            </td>
                            <td class='text-right'>{{ totals.combined.avzFutureAppTotalHrs }}</td>
                            <td class='text-right'>{{ totals.combined.avzPendingTotalHrs }}</td>
                            <td class='text-right'>{{ totals.combined.avzRejectedTotalHrs }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/services/api.service'

export default {
    props: ['type'],
    data() {
        return {
            syncing: false
        }
    },
    computed: {
        ...mapGetters('ProjectsSingle', [
            'entry',
        ]),
        ...mapGetters('CostUnits', [
            'loadingAnything',
            'avazaTimesheets',
            'scheduleCostUnits',
            'scheduleCostUnits',
        ]),
        reimbursableCostUnits() {
            return this.scheduleCostUnits.filter(costUnit => costUnit.type == 'reimbursable')
        },
        lumpSumCostUnits() {
            return this.scheduleCostUnits.filter(costUnit => costUnit.type == 'lumpSum')
        },
        hasReimbursable() {
            return this.avazaTimesheets.reimbursable || this.reimbursableCostUnits.length
        },
        hasLumpSum() {
            return this.avazaTimesheets.lumpSum || this.lumpSumCostUnits.length
        },
        totals() {
            const reSchBudgHrs = this.reimbursableCostUnits.reduce((hrs, costUnit) => hrs + costUnit.budget_hours, 0)
            const reSchActHrs = this.reimbursableCostUnits.reduce((hrs, costUnit) => hrs + costUnit.actual_hours, 0)
            const reAvzUnsyncedHrs = this.avazaTimesheets.reimbursable?.approved?.total_hours_to_end_of_last_period ?? 0
            const reAvzApprHrs = this.avazaTimesheets.reimbursable?.approved?.total_hours ?? 0
            const reAvzPendHrs = this.avazaTimesheets.reimbursable?.pendingOrDraft?.total_hours ?? 0
            const reAvzRejHrs = this.avazaTimesheets.reimbursable?.rejected?.total_hours ?? 0

            const lsSchBudgHrs = this.lumpSumCostUnits.reduce((hrs, costUnit) => hrs + costUnit.budget_hours, 0)
            const lsSchActHrs = this.lumpSumCostUnits.reduce((hrs, costUnit) => hrs + costUnit.actual_hours, 0)
            const lsAvzUnsyncedHrs = this.avazaTimesheets.lumpSum?.approved?.total_hours_to_end_of_last_period ?? 0
            const lsAvzApprHrs = this.avazaTimesheets.lumpSum?.approved?.total_hours ?? 0
            const lsAvzPendHrs = this.avazaTimesheets.lumpSum?.pendingOrDraft?.total_hours ?? 0
            const lsAvzRejHrs = this.avazaTimesheets.lumpSum?.rejected?.total_hours ?? 0

            return {
                reimbursable: {
                    schBudgetTotalHrs: reSchBudgHrs.toFixed(1),
                    schActualTotalHrs: reSchActHrs.toFixed(1),
                    avzUnsyncedAppTotalHrs: (reAvzUnsyncedHrs - reSchActHrs).toFixed(1),
                    avzFutureAppTotalHrs: (reAvzApprHrs - reAvzUnsyncedHrs).toFixed(1),
                    avzPendingTotalHrs: reAvzPendHrs.toFixed(1),
                    avzRejectedTotalHrs: reAvzRejHrs.toFixed(1),
                    BudgetRemTotalHrs: (reSchBudgHrs - reSchActHrs).toFixed(1),
                },
                lumpSum: {
                    schBudgetTotalHrs: lsSchBudgHrs.toFixed(1),
                    schActualTotalHrs: lsSchActHrs.toFixed(1),
                    avzUnsyncedAppTotalHrs: (lsAvzUnsyncedHrs - lsSchActHrs).toFixed(1),
                    avzFutureAppTotalHrs: (lsAvzApprHrs - lsAvzUnsyncedHrs).toFixed(1),
                    avzPendingTotalHrs: lsAvzPendHrs.toFixed(1),
                    avzRejectedTotalHrs: lsAvzRejHrs.toFixed(1),
                    BudgetRemTotalHrs: (lsSchBudgHrs - lsSchActHrs).toFixed(1),
                },
                combined: {
                    schBudgetTotalHrs: (reSchBudgHrs + lsSchBudgHrs).toFixed(1),
                    schActualTotalHrs: (reSchActHrs + lsSchActHrs).toFixed(1),
                    avzUnsyncedAppTotalHrs: (reAvzUnsyncedHrs - reSchActHrs + lsAvzUnsyncedHrs - lsSchActHrs).toFixed(1),
                    avzFutureAppTotalHrs: (reAvzApprHrs - reAvzUnsyncedHrs + lsAvzApprHrs - lsAvzUnsyncedHrs).toFixed(1),
                    avzPendingTotalHrs: (reAvzPendHrs + lsAvzPendHrs).toFixed(1),
                    avzRejectedTotalHrs: (reAvzRejHrs + lsAvzRejHrs).toFixed(1),
                    BudgetRemTotalHrs: (reSchBudgHrs - reSchActHrs + lsSchBudgHrs - lsSchActHrs).toFixed(1),
                },
            }
        }
    },
    methods: {
        syncActuals() {
            this.syncing = true
            ApiService
                .get(`projects/${this.entry.gte_project_id}/sync`)
                .then(response => console.log(response.data))
                .catch(error => errors.push(error.response))
                .finally(() => this.syncing = false)
        },
        hideScheduleColumns() {
            this.syncing = true
            ApiService
                .get(`projects/${this.entry.gte_project_id}/hide`)
                .then(response => console.log(response.data))
                .catch(error => errors.push(error.response))
                .finally(() => this.syncing = false)
        }
    }
}
</script>
