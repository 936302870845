<template>
    <div>
        <Summary type="lumpSum" v-if="showSummaryLumpSum" />
        <Summary type="reimbursable" v-if="showSummaryReimbursable" />

        <v-data-table
            :headers="headers"
            :items="costUnits"
            :itemsPerPage="1000"
            hide-default-footer
            item-key="id"
            :group-by="wbsCellString"
            class="elevation-1"
            fixed-header
            disable-sort
            :loading="loading"
        >
            <template v-slot:group.header="{ group, headers, isOpen, toggle }">
                <th :colspan="headers.length">
                <v-icon @click="toggle">
                    {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                <span class="overline">{{ group }}</span>
                </th>
            </template>
            <template v-slot:item.isReimbursable="{ item }">
                <v-chip
                    v-if="item.isReimbursable && (showSummaryLumpSum && showSummaryReimbursable)"
                    color="green lighten-4" small
                >R</v-chip>
            </template>
            <template v-slot:item.CostUnit="{ item }">
                {{ item.cells[schedule.columnKeysByName["Cost Code"]].displayValue + " " + item.cells[schedule.columnKeysByName["Activity"]].value }}
            </template>
            <template v-slot:item.Budget="{ item }">
                {{ item.cells[schedule.columnKeysByName["Budget [hrs]"]].value }}
            </template>
            <template v-slot:item.Actuals="{ item }">
                {{ item.cells[schedule.columnKeysByName["Actual [hrs]"]].value.toFixed(2) }}
            </template>
            <template v-slot:item.Avaza="{ item }">
                <v-chip
                    v-if="item.task"
                    class="ma-2"
                    :color="
                        item.cells[schedule.columnKeysByName['Actual [hrs]']].value == (item.task.ActualTime || 0)
                        ? 'green lighten-1'
                        : 'orange lighten-1'
                    "
                    small
                    label
                    outlined
                >
                    {{ (item.task.ActualTime || 0).toFixed(2) }}
                </v-chip>
           </template>
            <template v-slot:item.actions="{ item }">
                <AvazaCreateSingle :item="item" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Summary from "@/components/schedules/subs/Summary";
import AvazaCreateSingle from "@/components/avaza/subs/AvazaCreateSingle";

export default {
    components: {
        Summary,
        AvazaCreateSingle,
    },
    data() {
        return {
            syncing: false,
            headers: [
                { text: "", value: "isReimbursable" },
                { text: "Cost Unit", value: "CostUnit" },
                { text: "Budget (hrs)", value: "Budget", width: "120px", align: "center" },
                { text: "Actual (hrs)", value: "Actuals", width: "120px", align: "center"  },
                { text: "Avaza Time (hrs)", value: "Avaza", width: "150px", align: "center"  },
                { text: "Avaza Task", value: "actions", width: "150px", align: "center"  },
            ],
        };
    },
    created() {
        if (!this.costUnits.length && !this.determiningCostUnits) this.determineCostUnits()
    },
    watch: {
        'schedule.id' () {
            this.determineCostUnits()
        }
    },
    computed: {
        ...mapGetters("Schedule", [
            "loading",
            "schedule",
            "costUnits",
            "costUnitSummary",
        ]),
        showSummaryLumpSum() {
            return this.costUnitSummary.lumpSum?.totalCostUnits ?? false
        },
        showSummaryReimbursable() {
            return this.costUnitSummary.reimbursable?.totalCostUnits ?? false
        },
        wbsCellString() {
            return 'cells[' + this.schedule.columnKeysByName?.AvazaSection + '].value'
        },
    },
    methods: {
        ...mapActions("Schedule", ["determineCostUnits"]),
    },
}
</script>

