var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Alert"),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.data,
          "item-key": "key",
          "fixed-header": "",
          "disable-pagination": "",
          "hide-default-footer": "",
          "show-select": "",
          dense: "",
          "sort-by": ["avazaProject", "role", "location"],
          "sort-desc": [false, false, false],
          "multi-sort": "",
          loading: _vm.loading,
          search: _vm.search,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-toolbar-title", [
                      _vm._v(
                        "P" +
                          _vm._s(_vm.gteProjectId) +
                          " Timesheet (Role) Categories"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    !_vm.loading
                      ? _c("v-text-field", {
                          staticClass: "mx-4",
                          attrs: {
                            label: "Search",
                            "append-icon": "mdi-magnify",
                            "single-line": "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "primary",
                                          dark: "",
                                          small: "",
                                          loading: _vm.defining,
                                          disabled: _vm.loading,
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n                        Create/Update Rates\n                            "
                                    ),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [
                                        _vm._v(
                                          "\n                                mdi-menu-down\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "v-list",
                          _vm._l(
                            {
                              latest: "Using Latest Rates",
                              estimate: "Using Estimate Rates",
                            },
                            function (item, key) {
                              return _c(
                                "v-list-item",
                                { key: key },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.defineRates({
                                                gteProjectId: _vm.gteProjectId,
                                                type: key,
                                                roleCategories: _vm.selected,
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.avazaRate",
            fn: function (ref) {
              var item = ref.item
              return [
                !item.estimateRate > 0 &&
                !item.latestRate > 0 &&
                !(item.avazaRate == 0 || item.avazaRate == null)
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          label: "",
                                          color: "red",
                                          "text-color": "white",
                                          href:
                                            "https://gtegroup.avaza.com/project/view/" +
                                            item.avazaProjectId,
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(item.avazaRate)
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Role Category does not have a defined rate in either of the estimate or latest rates tables."
                          ),
                        ]),
                      ]
                    )
                  : item.avazaRate == 0 || item.avazaRate == null
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          label: "",
                                          color: "orange",
                                          "text-color": "white",
                                          href:
                                            "https://gtegroup.avaza.com/project/view/" +
                                            item.avazaProjectId,
                                        },
                                      },
                                      on
                                    ),
                                    [_vm._v("$0.00")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Role Category is established without a rate."
                          ),
                        ]),
                      ]
                    )
                  : item.avazaRate < item.latestRate
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          label: "",
                                          color: "white",
                                          "text-color": "orange",
                                          href:
                                            "https://gtegroup.avaza.com/project/view/" +
                                            item.avazaProjectId,
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(item.avazaRate)
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Project rate for the Role Category is lower than the latest rate."
                          ),
                        ]),
                      ]
                    )
                  : item.avazaRate === item.latestRate
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          label: "",
                                          color: "green",
                                          "text-color": "white",
                                          href:
                                            "https://gtegroup.avaza.com/project/view/" +
                                            item.avazaProjectId,
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(item.avazaRate)
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Project rate for the Role Category is equal to the latest rate."
                          ),
                        ]),
                      ]
                    )
                  : item.avazaRate > item.latestRate
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      {
                                        staticClass: "d-flex justify-center",
                                        attrs: {
                                          label: "",
                                          color: "white",
                                          "text-color": "red",
                                          href:
                                            "https://gtegroup.avaza.com/project/view/" +
                                            item.avazaProjectId,
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(item.avazaRate)
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "Project rate for the Role Category exceeds even the latest rate."
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.estimateRate",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("a", { attrs: { href: item.estimateLink } }, [
                  _vm._v(_vm._s(_vm._f("currency")(item.estimateRate))),
                ]),
              ]
            },
          },
          {
            key: "item.latestRate",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("a", { attrs: { href: item.latestLink } }, [
                  _vm._v(_vm._s(_vm._f("currency")(item.latestRate))),
                ]),
              ]
            },
          },
          {
            key: "item.avazaProject",
            fn: function (ref) {
              var item = ref.item
              return [
                item.avazaProject === "Reimbursable"
                  ? _c(
                      "v-chip",
                      { attrs: { color: "green lighten-4", small: "" } },
                      [_vm._v("R")]
                    )
                  : item.avazaProject === "Lump Sum"
                  ? _c(
                      "v-chip",
                      { attrs: { color: "blue lighten-4", small: "" } },
                      [_vm._v("LS")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }