<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="sectionsAndTasks.tasks"
            :itemsPerPage="1000"
            hide-default-footer
            item-key="id"
            group-by="SectionTitle"
            class="elevation-1"
            fixed-header
            disable-sort
            :loading="loadingSectionsAndTasks"
        >
            <template v-slot:group.header="{ group, headers, isOpen, toggle }">
                <th :colspan="headers.length">
                <v-icon @click="toggle">
                    {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                <span class="overline">{{ group }}</span>
                </th>
            </template>
            <template v-slot:item.inSchedule="{ item }">
                <v-icon v-if="costUnits.find(costUnit => costUnit.task && (costUnit.task.TaskID == item.TaskID) )">
                    mdi-chart-gantt
                </v-icon>
                <v-icon v-else color="red lighten-4">
                    mdi-circle-off-outline
                </v-icon>
                <v-chip
                    v-if="isReimbursable(item)"
                    color="green lighten-4" small
                >R</v-chip>
                <v-chip
                    v-else
                    color="blue lighten-4" small
                >LS</v-chip>
            </template>
            <template v-slot:item.Budget="{ item }">
                {{ item.EstimatedEffort ? item.EstimatedEffort.toFixed(2) : null }}
            </template>
            <template v-slot:item.Actuals="{ item }">
                {{ item.ActualTime ? item.ActualTime.toFixed(2) : 0.00 }}
            </template>

        </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Summary from "@/components/schedules/subs/Summary";
import AvazaCreateSingle from "@/components/avaza/subs/AvazaCreateSingle";

export default {
    components: {
        Summary,
        AvazaCreateSingle,
    },
    data() {
        return {
            syncing: false,
            headers: [
                { text: "", value: "inSchedule" },
                { text: "Task", value: "Title" },
                { text: "Budget (hrs)", value: "EstimatedEffort", width: "120px", align: "center" },
                { text: "Actual (hrs)", value: "Actuals", width: "120px", align: "center"  },
            ],
        };
    },
    created() {
        if (!this.costUnits.length && !this.determiningCostUnits) this.determineCostUnits()
    },
    computed: {
        ...mapGetters("AvazaProject", [
            "loading",
            "projects",
            "loadingSectionsAndTasks",
            "sectionsAndTasks",
        ]),
        ...mapGetters("Schedule", [
            "costUnits",
            "determiningCostUnits",
        ]),
        ...mapGetters("ProjectsSingle", [
            "entry",
        ]),
    },
    methods: {
        ...mapActions("Schedule", ["determineCostUnits"]),
        isReimbursable(item) {
            if (item.ProjectIDFK == this.projects.reimbursable?.ProjectID) return true
            return false
        },
    },
}
</script>

