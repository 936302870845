import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);

        Vue.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
        Vue.axios.defaults.baseURL = "/api/v1/";
        Vue.axios.defaults.withCredentials = true;

        Vue.axios.interceptors.request.use(
            request => {
                request.headers.common["Accept"] = "application/json";
                request.headers.common["Content-Type"] = "application/json";
                return request;
        });

        Vue.axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response.status === 401) {
                    console.log('Axios 401 encountered. Deleting user session and redirecting to login...');
                    sessionStorage.removeItem("user");
                    window.location.reload(); // will trigger redirect via middleware
                }
                return Promise.reject(error);
            }
        );
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch((error) => {
            // console.log(error);
            throw new Error(`ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, params) {
        return Vue.axios.get(`${resource}`, params);
    },

    // /**
    //  * Send the GET HTTP request
    //  * @param resource
    //  * @param slug
    //  * @returns {*}
    //  */
    // get(resource, slug = "") {
    //     return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
    //         // console.log(error);
    //         throw new Error(`ApiService ${error}`);
    //     });
    // },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch((error) => {
            // console.log(error);
            throw new Error(`ApiService ${error}`);
        });
    },
};

export default ApiService;
