var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Alert"),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "mb-2", attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "py-0" },
            [
              _c("span", { staticClass: "overline" }, [_vm._v("Maintenance")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-card-actions"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs",
                {
                  attrs: { "fixed-tabs": "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.items, function (item) {
                  return _c("v-tab", { key: item.tab }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.tab) +
                        "\n                "
                    ),
                  ])
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "v-tab-item",
                    { key: item.tab, attrs: { eager: true } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(item.content, {
                                tag: "component",
                                attrs: { "smartsheet-id": _vm.smartsheetId },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }