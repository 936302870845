import ApiService from "@/services/api.service";

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        details: [],
        loading: false // must leave as false
    }
}

const getters = {
    details: (state) => state.details,
    loading: (state) => state.loading,
}

const actions = {
    fetchRegisterData({ commit }, gteProjectId) {

        commit('setLoading', true)

        ApiService
        .get(`sheets/project_register/${gteProjectId}`)
        .then(response => {
            commit('setDetails', response.data[0])
        })
        .finally(() => {
            commit('setLoading', false)
        });
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setDetails: set('details'),
    setLoading: set('loading'),
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
