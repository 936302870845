var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _vm.createPermissive
      ? _c(
          "span",
          [
            _c(
              "v-btn",
              {
                attrs: {
                  text: "",
                  "x-small": "",
                  color: "orange accent-4",
                  disabled: _vm.creating,
                },
                on: {
                  click: function ($event) {
                    return _vm.createTask()
                  },
                },
              },
              [_vm._v("\n            Create Task\n        ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }