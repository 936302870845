import ApiService from '@/services/api.service'

const set = key => (state, val) => {
    state[key] = val
}

function getDefaultState() {
    return {
        loadingSchedule: false,
        loadingAvaza: false,
        loadingTimesheets: false,
        mapping: false,
        scheduleCostUnits: [],
        avazaCostUnits: [],
        avazaTimesheets: [],
        extraneousAvazaTasks: [],
    }
}

const getters = {
    loadingAnything: (state) => state.loadingSchedule || state.loadingAvaza || state.loadingTimesheets || state.mapping,
    loadingSchedule: (state) => state.loadingSchedule,
    loadingAvaza: (state) => state.loadingAvaza,
    loadingTimesheets: (state) => state.loadingTimesheets,
    mapping: (state) => state.mapping,
    scheduleCostUnits: (state) => state.scheduleCostUnits,
    avazaCostUnits: (state) => state.avazaCostUnits,
    avazaTimesheets: (state) => state.avazaTimesheets,
    extraneousAvazaTasks: (state) => state.extraneousAvazaTasks,
}

const actions = {
    fetchScheduleCostUnits({ state, commit, dispatch, rootGetters }) {
        commit('setLoadingSchedule', true)

        ApiService
            .get(`projects/cost-units/${rootGetters['ProjectsSingle/entry'].gte_project_id}/schedule`)
            .then(response => {
                // console.log(response.data) // for debug only
                commit('setScheduleCostUnits', response.data)
            })
            .catch(error => {
                console.log(error.response.data)
                dispatch('Alert/setAlert',
                    { message: 'Error loading Project Schedule', errors: error.response.data, type: 'error' },
                    { root: true }
                )
            })
            .finally(() => {
                commit('setLoadingSchedule', false)

                if( !state.loadingAvaza ) {
                    dispatch('addMappingsToScheduleCostUnits')
                }
            });
    },
    fetchAvazaCostUnits({ state, commit, dispatch, rootGetters }) {
        commit('setLoadingAvaza', true)

        ApiService
            .get(`projects/cost-units/${rootGetters['ProjectsSingle/entry'].gte_project_id}/avaza`)
            .then(response => {
                // console.log(response.data) // for debug only
                commit('setAvazaCostUnits', response.data)
            })
            .catch(error => {
                console.log(error.response.data)
                dispatch('Alert/setAlert',
                    { message: 'Error loading Avaza Project(s)', errors: error.response.data, type: 'error' },
                    { root: true }
                )
            })
            .finally(() => {
                commit('setLoadingAvaza', false)

                if( !state.loadingSchedule ) {
                    dispatch('addMappingsToScheduleCostUnits')
                }
            });
    },
    fetchAvazaTimesheets({ state, commit, dispatch, rootGetters }) {
        commit('setLoadingTimesheets', true)

        ApiService
            .get(`projects/cost-units/${rootGetters['ProjectsSingle/entry'].gte_project_id}/timesheets`)
            .then(response => {
                // console.log(response.data) // for debug only
                commit('setAvazaTimesheets', response.data)
            })
            .catch(error => {
                console.log(error.response.data)
                dispatch('Alert/setAlert',
                    { message: 'Error loading Avaza Timesheets', errors: error.response.data, type: 'error' },
                    { root: true }
                )
            })
            .finally(() => {
                commit('setLoadingTimesheets', false)
            });
    },
    addMappingsToScheduleCostUnits({ state, commit, dispatch }) {
        if( !state.scheduleCostUnits || !state.avazaCostUnits || state.loadingSchedule || state.loadingAvaza ) return

        var scheduleCostUnits = Object.assign(state.scheduleCostUnits)

        scheduleCostUnits.forEach((costUnit, key) => {

            const mappees = state.avazaCostUnits.tasks.filter(task => {
                return task.tags.map(tag => tag.Name).includes(costUnit.activity_id)
                        && task.type == costUnit.type
            })

            if( mappees && mappees.length > 1 ) {
                const errors = `Multiple Avaza tasks are tagged with the Activity ID '${costUnit.activity_id}'`
                dispatch('Alert/setAlert',
                    { message: 'Error mapping Schedule and Avaza', errors, type: 'error' },
                    { root: true }
                )
                console.log(errors)
                console.log(mappees)
            }

            if( mappees && mappees.length == 1 ) {
                scheduleCostUnits[key]['avaza'] = mappees[0]
            }
        })

        commit('setScheduleCostUnits', scheduleCostUnits)
        commit('setLoadingAvaza', false)

        dispatch('checkForExtraneousAvazaTasks')
    },
    checkForExtraneousAvazaTasks({ state, commit }) {
        if( !state.scheduleCostUnits || !state.avazaCostUnits || state.loadingSchedule || state.loadingAvaza) return

        var extraneous = [];

        state.avazaCostUnits.tasks.forEach(task => {
            if( !(
                state.scheduleCostUnits.find(costUnit => {
                    return task.tags.map(tag => tag.Name).includes(costUnit.activity_id) && task.type == costUnit.type
                })
            )) {
                extraneous.push(task)
            }
        })
        commit('setExtraneousAvazaTasks', extraneous)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setLoadingSchedule: set('loadingSchedule'),
    setLoadingAvaza: set('loadingAvaza'),
    setLoadingTimesheets: set('loadingTimesheets'),
    setMapping: set('mapping'),
    setScheduleCostUnits: set('scheduleCostUnits'),
    setAvazaCostUnits: set('avazaCostUnits'),
    setAvazaTimesheets: set('avazaTimesheets'),
    setExtraneousAvazaTasks: set('extraneousAvazaTasks'),
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
