<template>
    <v-app>
        <Alert />
        <v-data-table
            :headers="headers"
            :items="data.Timesheets"
            item-key="id"
            class="elevation-1 row-pointer"
            fixed-header
            show-select
            dense
            :sort-by="['Lastname', 'EntryDate', 'StartTimeLocal']"
            :sort-desc="[true, false, true]"
            multi-sort
            group-by="Lastname"
            :footer-props="{
                'items-per-page-options': [25, 50, 100, 200]
            }"
            :items-per-page="25"
            :loading="loading"
            @click:row="showItem"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Timesheets</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-row class="pb-4 mx-1">
                    <!-- <v-text-field
                        v-model="options.avazaProjectIds"
                        label="Project"
                        class="mx-4"
                        value="274732"
                    ></v-text-field> -->

                    <v-switch
                        v-if="avazaProjectIdsArr.length > 1"
                        v-model="options.avazaProjectId"
                        :true-value="avazaProjectIdsArr[1]"
                        :false-value="avazaProjectIdsArr[0]"
                        class="mx-4"
                    >
                        <template v-slot:label>
                            <div class="overline">Reimbursable</div>
                        </template>
                    </v-switch>

                    <v-spacer />

                    <v-menu
                        v-model="dateFromPicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="options.entryDateFrom"
                            label="Entry date from"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="options.entryDateFrom"
                            :first-day-of-week="firstDayOfWeek"
                            @input="dateFromPicker = false"
                        ></v-date-picker>
                    </v-menu>
                    <v-menu
                        v-model="dateToPicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="options.entryDateTo"
                            label="Entry date to"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="options.entryDateTo"
                            :first-day-of-week="firstDayOfWeek"
                            @input="dateToPicker = false"
                        ></v-date-picker>
                    </v-menu>
                </v-row>

            </template>

            <template v-slot:group.header="{ group, headers, isOpen, toggle, items }">
                <th :colspan="headers.length">
                <v-icon @click="toggle">
                    {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                <span class="overline">{{ items[0].Firstname }} {{ items[0].Lastname }}</span>
                </th>
            </template>

            <template v-slot:item.EntryDate="{ item }">
                <span style="white-space: nowrap;">{{ dateFormat(item.EntryDate) }}</span>
            </template>

            <template v-slot:item.Time="{ item }">
                <div class="text-center">
                    <v-chip class="ma-1" label color="light-blue lighten-4">
                        <!-- API doesn't return any true results so below is always false...
                            :color="item.HasTimer == 'true' ? 'green lighten-4' : 'red lighten-4'" -->
                        {{ timeFormat(item.StartTimeLocal) }} - {{ timeFormat(item.EndTimeLocal) }}
                    </v-chip>
                </div>
            </template>

            <template v-slot:item.Duration="{ item }">
                <div class="text-center">
                    <v-chip class="ma-1" outlined>
                        {{ item.Duration.toFixed(2) }}
                    </v-chip>
                </div>
            </template>

            <template v-slot:item.CategoryName="{ item }">
                <v-chip v-if="item.CategoryName" class="ma-1" label outlined>
                    {{ item.CategoryName }}
                </v-chip>
                <v-chip v-else class="ma-1" label>
                    No Category
                </v-chip>
            </template>

            <template v-slot:item.TimesheetEntryApprovalStatusCode="{ item }">

                <v-chip v-if="item.TimesheetEntryApprovalStatusCode === 'Approved'
                        || item.TimesheetEntryApprovalStatusCode === 'Automatically Approved'"
                    class="ma-1" label color="green" text-color="white"
                >{{ item.TimesheetEntryApprovalStatusCode === 'Approved' ? 'Approved' : 'Auto Approved' }}
                </v-chip>

                <v-chip v-if="item.TimesheetEntryApprovalStatusCode === 'Draft'"
                    class="ma-1" label color="grey" text-color="white"
                >{{ item.TimesheetEntryApprovalStatusCode }}
                </v-chip>

                <v-chip v-if="item.TimesheetEntryApprovalStatusCode === 'Pending'"
                    class="ma-1" label color="amber" text-color="white"
                >{{ item.TimesheetEntryApprovalStatusCode }}
                </v-chip>

                <v-chip v-if="item.TimesheetEntryApprovalStatusCode === 'Rejected'"
                    class="ma-1" label color="red" text-color="white"
                >{{ item.TimesheetEntryApprovalStatusCode }}
                </v-chip>

                <v-chip v-if="item.TimesheetEntryApprovalStatusCode === 'Invoiced'"
                    class="ma-1" label color="light-blue" text-color="white"
                >{{ item.TimesheetEntryApprovalStatusCode }}
                </v-chip>

            </template>

        </v-data-table>

    </v-app>
</template>

<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/datejs/1.0/date.min.js"></script>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: ["avazaProjectIds"],
    data() {
        return {
            headers: [
                { text: "Lastname", value: "Lastname" },
                { text: "Entry Date", value: "EntryDate", sortable: false },
                { text: "Task/Activity", value: "TaskTitle", sortable: false },
                { text: "Time", value: "Time", sortable: false },
                { text: "Duration", value: "Duration", sortable: false },
                { text: "Notes", value: "Notes", sortable: false },
                { text: "Category", value: "CategoryName", sortable: false },
                { text: "Status", value: "TimesheetEntryApprovalStatusCode", sortable: false },

            ],
            options: {
                avazaProjectId: this.avazaProjectIds.substr(0,6),
                entryDateFrom: this.defaultStartDate(),
                entryDateTo: this.defaultEndDate(),
                sort: "EntryDate",
                pageSize: 1000
            },
            dateFromPicker: false,
            dateToPicker: false,
            reimbursableOrLumpSum: 0,
        };
    },
    created() {
        this.setQuery(this.options);
        this.fetchIndexData();
    },
    beforeDestroy() {
        this.resetState();
    },
    computed: {
        ...mapGetters("AvazaProjectTimesheets", [
            "data",
            "pagination",
            "loading"
        ]),
        avazaProjectIdsArr() {
            return this.avazaProjectIds.split(",")
        },
        firstDayOfWeek() {
            return startOfTheWeek
        }
    },
    watch: {
        options: {
            handler() {
                this.setQuery(this.options);
                this.fetchIndexData();
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions("AvazaProjectTimesheets", [
            "fetchIndexData",
            "setQuery",
            "resetState",
        ]),
        showItem(item) {
            window.open(
                `https://gtegroup.avaza.com/timesheet/day/${item.EntryDate.substring(0,10).split('-').join('')}?userID=${item.UserIDFK}`,
                '_blank'
            ).focus()
        },
        dateFormat(time) {
            var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            var date = new Date(time);
            var year = date.getFullYear();
            var month =
             date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
            var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            return days[date.getDay()] + " " + day + "/" + month;
        },
        timeFormat(time) {
            var date = new Date(time);
            var hours =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minutes =
                date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            return hours + ":" + minutes;
        },
        getDayCustomized(time) {
            // returns the day of the week (0 to 6) of a date. startOfTheWeek is taken from config/api.php
            var offset = startOfTheWeek > 4 ? startOfTheWeek - 5 : startOfTheWeek + 2;
            return (time.getDay() + offset)  % 7;
        },
        defaultStartDate() {
            var curr = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)) // get current date
            var first = curr.getDate() - this.getDayCustomized(curr) - 7 // first day is the day of the month - the day of the week
            return new Date(curr.setDate(first)).toISOString().substr(0, 10)
        },
        defaultEndDate() {
            var curr = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)) // get current date
            var first = curr.getDate() - this.getDayCustomized(curr) - 7 // first day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6
            return new Date(curr.setDate(last)).toISOString().substr(0, 10)
        },
    },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
