import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import 'vuetify/dist/vuetify.min.css'

const opts = {
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#0092BC", //"#1976D2",
                secondary: "#F0AB00", //"#81C784",
                accent: "#5d78ff",
                error: "#fd397a",
                info: "#5578eb",
                success: "#0abb87",
                warning: "#ffb822"
            }
        }
    }
}

export default new Vuetify(opts)
