<template>
    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="sheets"
        item-key="smartsheet_id"
        :options.sync="options"
        :server-items-length="pagination.total"
        :footer-props="{
            'items-per-page-options': [25, 50, 100, 250]
        }"
        :items-per-page="50"
        show-select
        show-expand
        dense
    >
        <template v-slot:top>
            <v-row class="px-4">

                <span class="overline">
                    {{ cachingBatch.status }}
                </span>
                <v-spacer />
                <v-btn
                    :disabled="batching"
                    :outlined="cachingBatch.progress < 100"
                    :color="cachingBatch.progress < 100 ? 'secondary' : 'info'"
                    small
                    class="mb-2"
                    @click="updateSmartsheetsCache"
                >
                    {{ 'Update Cache' }}
                </v-btn>

            </v-row>

            <v-row class="pb-4">
                <v-text-field
                    v-model="options.keyword"
                    label="Search"
                    class="mx-4"
                    hint="Searches name, workspace, template, columns and summary fields only."
                    persistent-hint
                ></v-text-field>
                <v-spacer />
                <v-switch v-model="options.startingWithP" class="mr-2">
                    <template v-slot:label>
                        <div class="overline">Starting "P" Only</div>
                    </template>
                </v-switch>
                <v-spacer />
                <v-switch v-model="options.templated" class="mx-2">
                    <template v-slot:label>
                        <div class="overline">Templated Only</div>
                    </template>
                </v-switch>
                <v-spacer />
                <v-switch v-model="options.hideLocked" class="ml-2 mr-4">
                    <template v-slot:label>
                        <div class="overline">Hide Locked</div>
                    </template>
                </v-switch>
            </v-row>

            <v-row class="pb-4 px-4">
                Selected: {{ selectedSheets.length }}
            </v-row>

        </template>

        <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
                v-if="item.column_names"
                :value="isSelected"
                @input="select($event)"
                :ripple="false"
            ></v-simple-checkbox>
        </template>

        <template v-slot:item.status="{ item }">
            <v-icon
                small
                color="orange lighten-2"
                v-if="Boolean(item.is_locked)"
            >mdi-lock</v-icon>
            <v-icon
                small
                color="gray darken-2"
                v-if="!Boolean(item.is_loaded)"
            >mdi-progress-clock</v-icon>
            <v-icon
                small
                color="green lighten-2"
                v-if="Boolean(item.is_loaded) && !Boolean(item.is_locked)"
            >mdi-cloud-check</v-icon>
        </template>

        <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                <v-simple-table dense class="font-weight-light">
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td class="overline" style="width: 20%">Columns</td>
                                <td>{{ item.column_names }}</td>
                            </tr>
                            <tr>
                                <td class="overline" style="width: 20%">Primary Column</td>
                                <td>{{ item.primary_column_name }}</td>
                            </tr>
                            <tr v-if="item.summary_fields" >
                                <td class="overline" style="width: 20%">Summary Fields</td>
                                <td>{{ item.summary_fields }}</td>
                            </tr>
                            <tr>
                                <td class="overline" style="width: 20%">Smartsheet ID</td>
                                <td><a :href="item.permalink" class="caption">{{ item.smartsheet_id }}</a></td>
                            </tr>
                            <tr v-if="Boolean(item.resource_management_enabled)">
                                <td class="overline" style="width: 20%">Res. Mgmt Enabled</td>
                                <td><v-simple-checkbox
                                    disabled
                                    :value="true"
                                    light
                                ></v-simple-checkbox></td>
                            </tr>
                            <tr v-if="item.gte_template">
                                <td class="overline" style="width: 20%">Template</td>
                                <td>{{ item.gte_template }}<span v-if="item.gte_template_build_date"> ({{ item.gte_template_build_date }})</span></td>
                            </tr>
                            <tr>
                                <td class="overline" style="width: 20%">Owner</td>
                                <td>{{ item.owner }}</td>
                            </tr>
                            <tr v-if="item.loaded_at">
                                <td class="overline" style="width: 20%">Last Cached</td>
                                <td>{{ item.loaded_at }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </td>
        </template>

    </v-data-table>

</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            headers: [
                { text: "", value: "status" },
                { text: "Name", value: "name" },
                { text: "Workspace", value: "workspace_name" },
                { text: "Template", value: "gte_template" },
                { text: "Rows", value: "total_row_count" },
            ],
            options: {
                startingWithP: true,
                templated: true,
                hideLocked: true,
                sortBy: ["name"]
            },
            counter: 0,
        };
    },
    created() {
        this.fetchSmartsheetsCache()
        this.fetchCachingStatus()

        let vm = this
        Echo.channel('cached-sheets-queue')
            .listen('.done', (e) => {
                this.getCachingStatus()
            })
    },
    watch: {
        options: {
            handler(options) {
                this.setQuery(options)
                this.fetchSmartsheetsCache()
            },
            deep: true
        },
    },
    computed: {
        ...mapGetters("SmartsheetsCache", [
            "sheets",
            "cachingBatch",
            "batching",
            "pagination",
            "selectedSheets"
        ]),
        selected: {
            get () {
                return this.selectedSheets
            },
            set (value) {
                // remove those results without a checkbox (which we hid because they don't have columns that we can access)
                this.setSelectedSheets( value.filter(item => item.column_names) )
            }
        },
    },
    methods: {
        ...mapActions("SmartsheetsCache", [
            "updateSmartsheetsCache",
            "fetchCachingStatus",
            "fetchSmartsheetsCache",
            "setQuery",
            "setSelectedSheets",
            "setSheet"
        ]),
        getCachingStatus() {
            this.counter++

            if (this.counter >= 5 || this.cachingBatch.pendingJobs <= 5) {
                this.fetchCachingStatus()
                this.fetchSmartsheetsCache()
                this.counter = 0
            }
        }
    },
};
</script>
