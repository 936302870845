var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-banner",
        {
          staticClass: "mb-2",
          attrs: { "single-line": "" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        text: "",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.fire },
                    },
                    [_vm._v("\n                Fire\n            ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-icon",
            {
              attrs: { slot: "icon", color: "yellow accent-4", size: "36" },
              slot: "icon",
            },
            [_vm._v("mdi-alarm-light")]
          ),
          _vm._v("\n        Test Page\n\n        "),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Sheets processed: " +
            _vm._s(_vm.processed) +
            " of " +
            _vm._s(_vm.sheetCount)
        ),
      ]),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.sheets,
          "item-key": "id",
          search: _vm.search,
          "items-per-page": 1000,
          loading: _vm.loading,
          "show-expand": "",
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c("v-text-field", {
                  staticClass: "mx-4",
                  attrs: { label: "Search" },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item.countHypenEmail",
            fn: function (ref) {
              var item = ref.item
              return [
                item.searchFound.hypenEmail &&
                item.searchFound.hypenEmail.length
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-2",
                        attrs: {
                          color: "red darken-2",
                          small: "",
                          label: "",
                          outlined: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.searchFound.hypenEmail.length) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.countSsEmail",
            fn: function (ref) {
              var item = ref.item
              return [
                item.searchFound.ssEmail && item.searchFound.ssEmail.length
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-2",
                        attrs: {
                          color: "red darken-2",
                          small: "",
                          label: "",
                          outlined: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.searchFound.ssEmail.length) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.countVLookup",
            fn: function (ref) {
              var item = ref.item
              return [
                item.searchFound.vLookup && item.searchFound.vLookup.length
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "ma-2",
                        attrs: {
                          color: "red darken-2",
                          small: "",
                          label: "",
                          outlined: "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.searchFound.vLookup.length) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "expanded-item",
            fn: function (ref) {
              var headers = ref.headers
              var item = ref.item
              return [
                _c(
                  "td",
                  { staticClass: "p-0", attrs: { colspan: headers.length } },
                  [
                    item.searchFound.hypenEmail &&
                    item.searchFound.hypenEmail.length
                      ? _c("div", { staticClass: "font-weight-black" }, [
                          _vm._v(
                            '\n                    Emails with "gte-group"\n                    '
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.searchFound.hypenEmail) +
                                "\n                    "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.searchFound.ssEmail && item.searchFound.ssEmail.length
                      ? _c("div", { staticClass: "font-weight-black" }, [
                          _vm._v(
                            '\n                    Emails with ".ss"\n                    '
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.searchFound.ssEmail) +
                                "\n                    "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.searchFound.vLookup && item.searchFound.vLookup.length
                      ? _c("div", { staticClass: "font-weight-black" }, [
                          _vm._v(
                            "\n                    VLOOKUPs\n                    "
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.searchFound.vLookup) +
                                "\n                    "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }