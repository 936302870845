<template>
    <v-container>
        <span v-if="!loadingSectionsAndTasks"> <!-- check Avaza loaded -->
            <v-btn
                v-if="item.task != null"
                text
                x-small
                color="green accent-4"
                :href="'https://gtegroup.avaza.com/project/view/'
                    + item.task.ProjectIDFK
                    + '#!tab=task-pane&groupby=ProjectSection&view=vertical&task='
                    + item.task.TaskID + '&fileview=grid'"
                target="_blank"
            >
                Task {{ item.task.TaskID }}
            </v-btn>

            <span v-else>
                <span v-if="projectIDFK && !rowIssue">
                    <v-btn
                        v-if="item.section == null"
                        text
                        x-small
                        color="orange accent-4"
                        @click="addSectionAndTask()"
                        :loading="loading"
                    >
                        Create Section &amp; Task
                    </v-btn>
                    <v-btn
                        v-if="item.section != null"
                        text
                        x-small
                        color="orange accent-4"
                        @click="addTask(item.section.SectionID)"
                        :loading="loading"
                    >
                        Create Task (Section exists)
                    </v-btn>
                </span>
            </span>

            <span class="caption red--text">
                {{ rowIssue }}
            </span>

        </span>
        <div v-else style="width:50px; display:inline-block;">
            <v-progress-circular
                v-if="projectIDFK"
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>

    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    props: ["item"],
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapGetters("AvazaProject", [
            "loadingSectionsAndTasks",
        ]),
        ...mapGetters("ProjectsSingle", [
            "entry",
        ]),
        ...mapGetters("Schedule", [
            "schedule",
            "costUnits",
        ]),
        projectIDFK() {
            return this.item.isReimbursable ? this.entry.avaza_reimbursable_project_id : this.entry.avaza_lumpsum_project_id
        },
        rowIssue() {
            if (this.item.cells[ this.schedule.columnKeysByName['Cost Unit Issues'] ]?.hasOwnProperty('displayValue')) {
                return this.item.cells[ this.schedule.columnKeysByName['Cost Unit Issues'] ].displayValue
            }
            return null
        },
    },
    methods: {
        ...mapActions("AvazaProject", [
            "fetchAvazaSectionsAndTasks",
            "createSection",
            "createTask",
        ]),
        addSectionAndTask() {
            this.loading = true

            const section = this.costUnits.filter(costUnit => costUnit.id == this.item.id).map((row) => ({
                ProjectIDFK: this.projectIDFK,
                Title: row.avazaCreation.section.Title,
            }))[0]

            this.createSection(section)
                .then((response) => {
                    this.addTask(response.sectionId)
                })

        },
        addTask(sectionId) {
            this.loading = true

            const task = this.costUnits.filter(costUnit => costUnit.id == this.item.id).map((row) => ({
                ProjectIDFK: this.projectIDFK,
                SectionIDFK: sectionId,
                ...row.avazaCreation.task
            }))[0]

            this.createTask({
                taskObject: task,
                skipBudgetConstraint: true
            })
                .then(() => {
                    this.fetchAvazaSectionsAndTasks()
                })
                .finally(() => {
                    this.loading = false
                })
        },
    }
};
</script>
