<template>

    <span :class='textClass'>
        <v-tooltip v-if='Math.abs(timeValue) > 0.01 && tooltipText' bottom>
            <template v-slot:activator='{ on }'>
                <span v-on='on' :class='textClass' style='line-height: 100%'>
                    {{ timeValue.toFixed(1) }}
                </span>
            </template>
            <span>{{ tooltipText }}</span>
        </v-tooltip>
        <span v-else :class='textClass'>
            {{ Math.abs(timeValue) > 0.01 ? timeValue.toFixed(1) : '' }}
        </span>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['item', 'timeType'],
    computed: {
        ...mapGetters('CostUnits', [
            'loadingTimesheets',
            'avazaTimesheets',
        ]),
        avazaTaskId() {
            return this.item.avaza?.id
        },
        timeValue() {
            if( this.timeType == 'unsyncedApproved' ) {
                return this.avazaTaskId && this.avazaTimesheets?.[this.item.type]?.hasOwnProperty('approved')
                    ? (this.avazaTimesheets?.[this.item.type]?.approved[this.avazaTaskId]?.total_hours_to_end_of_last_period ?? 0) - this.item.actual_hours
                    : null
            }

            if( this.timeType == 'futureApproved' ) {
                return this.avazaTaskId && this.avazaTimesheets?.[this.item.type]?.hasOwnProperty('approved')
                    ? (this.avazaTimesheets?.[this.item.type]?.approved[this.avazaTaskId]?.total_hours?? 0) - (this.avazaTimesheets?.[this.item.type]?.approved[this.avazaTaskId]?.total_hours_to_end_of_last_period ?? 0)
                    : null
            }

            if( this.timeType == 'pendingOrDraft' ) {
                return this.avazaTaskId && this.avazaTimesheets?.[this.item.type]?.hasOwnProperty('pendingOrDraft')
                    ? this.avazaTimesheets?.[this.item.type]?.pendingOrDraft[this.avazaTaskId]?.total_hours
                    : null
            }

            if( this.timeType == 'rejected' ) {
                return this.avazaTaskId && this.avazaTimesheets?.[this.item.type]?.hasOwnProperty('rejected')
                    ? this.avazaTimesheets?.[this.item.type]?.rejected[this.avazaTaskId]?.total_hours
                    : null
            }
        },
        tooltipText() {
            if( this.timeType == 'unsyncedApproved' ) {
                return this.avazaTaskId && this.avazaTimesheets?.[this.item.type]?.hasOwnProperty('approved')
                    ? 'Avaza indicates '
                        + (this.avazaTimesheets?.[this.item.type]?.approved[this.avazaTaskId]?.total_hours_to_end_of_last_period ?? 0).toFixed(1)
                        + ' approved hrs to end of last period'
                    : null
            }

            if( this.timeType == 'futureApproved' ) {
                return this.avazaTaskId && this.avazaTimesheets?.[this.item.type]?.hasOwnProperty('approved')
                    ? 'Avaza indicates '
                        + (this.avazaTimesheets?.[this.item.type]?.approved[this.avazaTaskId]?.total_hours ?? 0).toFixed(1)
                        + ' approved hrs to date (now)'
                    : null
            }

            return 0
        },
        textClass() {
            if( Math.abs(this.timeValue) < 0.01 ) {
                return 'grey--text lighten-3'
            }

            if( this.timeValue > 0 ) {
                return 'red--text lighten-3'
            }

            if( this.timeValue < 0) {
                return 'orange--text lighten-3'
            }
        },
    }
};
</script>
