import { render, staticRenderFns } from "./CostUnits.vue?vue&type=template&id=2911c990&"
import script from "./CostUnits.vue?vue&type=script&lang=js&"
export * from "./CostUnits.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VChip,VChipGroup,VDataTable,VIcon,VSimpleTable,VTab,VTabItem,VTabs,VTabsItems,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\jordan.harun\\Documents\\GitHub\\gte-portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2911c990')) {
      api.createRecord('2911c990', component.options)
    } else {
      api.reload('2911c990', component.options)
    }
    module.hot.accept("./CostUnits.vue?vue&type=template&id=2911c990&", function () {
      api.rerender('2911c990', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/projects/CostUnits.vue"
export default component.exports