<template>
    <v-card class="mx-auto mb-5 elevation-1">
        <v-card-title class="overline">
            {{ type == "reimbursable" ? "Reimbursable" : "Lump Sum"}}

            <v-spacer />
            <span v-if="entry.last_synced" class="caption">Last synced: {{ entry.last_synced }}</span>

            <v-spacer />
            <v-card-actions v-if="costUnitSummary[type].hasOwnProperty('totalWithTask') && avazaSummary[type].hasOwnProperty('totalActualHours')">
                <v-btn
                    small
                    v-if="(costUnitSummary[type].totalWithTask == costUnitSummary[type].totalCostUnits || true) //temp disabled
                            "
                    :disabled="avazaSummary[type].totalActualHours.toFixed(2) == costUnitSummary[type].totalActualHours.toFixed(2)"
                    @click="syncActuals(type)"
                    :loading="syncing"
                    light
                    text
                    color="primary"
                >
                    Sync Schedule &amp; Avaza
                </v-btn>
            </v-card-actions>

        </v-card-title>

        <v-card-text>
            <v-row>
                <v-col>
                    <div>Budget (hrs)</div>
                    <p class="display-1 text--primary">
                        {{ costUnitSummary[type].totalBudgetHours }}
                        <span v-if="!loadingSectionsAndTasks" class="caption">
                            {{ avazaSummary[type].totalBudgetHours }} in Avaza
                        </span>
                    </p>
                    <div v-if="costUnitSummary[type].totalBudgetHours > 0 && !loadingSectionsAndTasks" class="overline">
                        <span v-if="avazaSummary[type].totalBudgetHours == costUnitSummary[type].totalBudgetHours">
                            Budgets in sync
                        </span>
                        <span v-else class="orange--text">
                            Budget discrepancy: {{ avazaSummary[type].totalBudgetHours - costUnitSummary[type].totalBudgetHours }}
                        </span>
                    </div>
                </v-col>
                <v-col>
                    <div>Actuals (hrs)</div>
                    <p class="display-1 text--primary">
                        {{ costUnitSummary[type].totalActualHours.toFixed(2) }}
                        <span v-if="!loadingSectionsAndTasks" class="caption">
                            {{ avazaSummary[type].totalActualHours.toFixed(2) }} in Avaza
                        </span>
                    </p>
                    <div v-if="costUnitSummary[type].totalBudgetHours > 0 && !loadingSectionsAndTasks" class="overline">
                        <span v-if="avazaSummary[type].totalActualHours.toFixed(2) == costUnitSummary[type].totalActualHours.toFixed(2)">
                            Actuals in sync
                        </span>
                        <span v-else class="orange--text">
                            Actuals discrepancy: {{ (avazaSummary[type].totalActualHours - costUnitSummary[type].totalActualHours).toFixed(2) }}
                        </span>
                    </div>
                </v-col>
                <v-col>
                    <div>#</div>
                    <p class="display-1 text--primary">
                        {{ costUnitSummary[type].totalCostUnits }}
                        <span v-if="!loadingSectionsAndTasks" class="caption">
                            {{ costUnitSummary[type].totalWithTask }} in Avaza
                            <span v-if="avazaSummary[type].totalTasks != costUnitSummary[type].totalWithTask">
                                (+ {{ avazaSummary[type].totalTasks - costUnitSummary[type].totalWithTask }} others)
                            </span>
                        </span>
                    </p>
                    <div v-if="costUnitSummary[type].totalBudgetHours > 0 && !loadingSectionsAndTasks" class="overline">
                        <span v-if="costUnitSummary[type].totalWithTask == costUnitSummary[type].totalCostUnits">
                            Tasks aligned
                        </span>
                        <span v-else class="red--text">
                            Avaza is missing tasks
                        </span>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>

    </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import ApiService from "@/services/api.service"

export default {
    props: [
        "type"
    ],
    data() {
        return {
            syncing: false
        }
    },
    computed: {
        ...mapGetters("ProjectsSingle", [
            "entry",
        ]),
        ...mapGetters("Schedule", [
            "costUnitSummary"
        ]),
        ...mapGetters("AvazaProject", [
            "loadingSectionsAndTasks",
            "avazaSummary",
        ]),
    },
    methods: {
        syncActuals() {
            this.syncing = true
            ApiService
                .get(`projects/${this.entry.gte_project_id}/sync`)
                .then(response => console.log(response.data))
                .catch(error => errors.push(error.response))
                .finally(() => this.syncing = false)
        }
    }
}
</script>
