<template>
    <v-card
        :loading="loading"
        outlined
        class="mb-2"
    >
        <v-card-title class="py-0">
            <span class="overline">Project Register Details</span>
            <v-spacer />
            <v-card-actions>
                <v-btn
                    color="secondary"
                    text
                    href="https://app.smartsheet.com/sheets/cjMrq22wxV6pHhfX2mJMWJpgW2vFxr86hfc2Vrh1"
                    target="_blank"
                >
                    Register
                    <v-icon right>mdi-table</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card-title>

        <v-card-text v-if="!loading && !details['Project ID']">
            <span class="caption">The project could not be found in the Project Register.</span>
        </v-card-text>

        <v-card-text v-if="!loading && details['Project ID']">
            <v-row>
                <v-col cols="6">
                    <v-simple-table dense>
                        <template v-slot:default>
                        <thead><tr>
                            <th class="text-left" colspan="2"></th>
                        </tr></thead>
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>{{ details['Project Name'] }}</td>
                            </tr>
                            <tr>
                                <td>Type:</td>
                                <td>{{ details['Type'] }}</td>
                            </tr>
                            <tr>
                                <td>Client:</td>
                                <td>{{ details['Client'] }}
                                    {{ details['Client PO Entity'] ? ' (' + details['Client PO Entity'] + ')' : '' }}
                                </td>
                            </tr>
                            <tr>
                                <td>GTE PM:</td>
                                <td>{{ details['GTE PM'] }}</td>
                            </tr>
                            <tr>
                                <td>Stage:</td>
                                <td>{{ details['Stage'] }}</td>
                            </tr>
                            <tr>
                                <td>Delivery Status:</td>
                                <td>{{ details['Delivery Status'] }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>

                    </v-list>
                </v-col>
                <v-col>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead><tr>
                                <th class="text-left" colspan="2">Budget Hrs</th>
                            </tr></thead>
                            <tbody>
                                <tr>
                                    <td>Budget at Award</td>
                                    <td class="text-right">{{ hoursFormat(details['Total Budget at Award [hrs]']) }}</td>
                                </tr>
                                <tr>
                                    <td>Requested Change</td>
                                    <td class="text-right">{{ hoursFormat(details['Total Requested Change Budget [hrs]']) }}</td>
                                </tr>
                                <tr>
                                    <td>Approved Change</td>
                                    <td class="text-right">{{ hoursFormat(details['Total Approved Change Budget [hrs]']) }}</td>
                                </tr>
                                <tr>
                                    <td>Total Budget</td>
                                    <td class="text-right">{{ hoursFormat(details['Total Budget [hrs]']) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead><tr>
                                <th class="text-left" colspan="2">Budget $</th>
                            </tr></thead>
                            <tbody>
                                <tr>
                                    <td>Budget at Award</td>
                                    <td class="text-right">{{ currencyFormat(details['Total Budget at Award [$]']) }}</td>
                                </tr>
                                <tr>
                                    <td>Requested Change</td>
                                    <td class="text-right">{{ currencyFormat(details['Total Requested Change Budget [$]']) }}</td>
                                </tr>
                                <tr>
                                    <td>Approved Change</td>
                                    <td class="text-right">{{ currencyFormat(details['Total Approved Change Budget [$]']) }}</td>
                                </tr>
                                <tr>
                                    <td>Total Budget</td>
                                    <td class="text-right">{{ currencyFormat(details['Total Budget [$]']) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>

    </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters("ProjectsSingle", ["entry"]),
        ...mapGetters("ProjectRegister", ["details", "loading"]),
    },
    watch: {
        'entry.gte_project_id' (newVal, oldVal) {
            newVal && newVal != oldVal ? this.fetchRegisterData(this.entry.gte_project_id) : null
        }
    },
    methods: {
        ...mapActions("ProjectRegister", ["fetchRegisterData"]),
        currencyFormat(num) {
            if(!num) num = 0
            return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        hoursFormat(num) {
            if(!num) num = 0
            return num.toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    },
};
</script>
