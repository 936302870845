var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    !_vm.loadingSectionsAndTasks
      ? _c(
          "span",
          [
            _vm.item.task != null
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      text: "",
                      "x-small": "",
                      color: "green accent-4",
                      href:
                        "https://gtegroup.avaza.com/project/view/" +
                        _vm.item.task.ProjectIDFK +
                        "#!tab=task-pane&groupby=ProjectSection&view=vertical&task=" +
                        _vm.item.task.TaskID +
                        "&fileview=grid",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      "\n            Task " +
                        _vm._s(_vm.item.task.TaskID) +
                        "\n        "
                    ),
                  ]
                )
              : _c("span", [
                  _vm.projectIDFK && !_vm.rowIssue
                    ? _c(
                        "span",
                        [
                          _vm.item.section == null
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    color: "orange accent-4",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSectionAndTask()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Create Section & Task\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.item.section != null
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    color: "orange accent-4",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTask(
                                        _vm.item.section.SectionID
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Create Task (Section exists)\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
            _vm._v(" "),
            _c("span", { staticClass: "caption red--text" }, [
              _vm._v("\n            " + _vm._s(_vm.rowIssue) + "\n        "),
            ]),
          ],
          1
        )
      : _c(
          "div",
          { staticStyle: { width: "50px", display: "inline-block" } },
          [
            _vm.projectIDFK
              ? _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "primary" },
                })
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }