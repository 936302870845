var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { class: _vm.textClass },
    [
      Math.abs(_vm.timeValue) > 0.01 && _vm.tooltipText
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "span",
                          _vm._g(
                            {
                              class: _vm.textClass,
                              staticStyle: { "line-height": "100%" },
                            },
                            on
                          ),
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.timeValue.toFixed(1)) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3570362424
              ),
            },
            [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.tooltipText))])]
          )
        : _c("span", { class: _vm.textClass }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  Math.abs(_vm.timeValue) > 0.01 ? _vm.timeValue.toFixed(1) : ""
                ) +
                "\n    "
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }