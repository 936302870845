var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Alert"),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "elevation-1 row-pointer",
        attrs: {
          headers: _vm.headers,
          items: _vm.data,
          options: _vm.options,
          "item-key": "id",
          "fixed-header": "",
          dense: "",
          "footer-props": {
            "items-per-page-options": [50, 100, 200],
          },
          "server-items-length": _vm.pagination.total,
          "items-per-page": 50,
          loading: _vm.loading,
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
          "click:row": _vm.showItem,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-toolbar-title", [_vm._v("Projects")]),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c("v-text-field", {
                      staticClass: "pt-4",
                      attrs: { label: "Search Project ID" },
                      model: {
                        value: _vm.options.keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "keyword", $$v)
                        },
                        expression: "options.keyword",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }