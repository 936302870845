import { render, staticRenderFns } from "./AvazaTasks.vue?vue&type=template&id=33e2803c&"
import script from "./AvazaTasks.vue?vue&type=script&lang=js&"
export * from "./AvazaTasks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VDataTable,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\jordan.harun\\Documents\\GitHub\\gte-portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33e2803c')) {
      api.createRecord('33e2803c', component.options)
    } else {
      api.reload('33e2803c', component.options)
    }
    module.hot.accept("./AvazaTasks.vue?vue&type=template&id=33e2803c&", function () {
      api.rerender('33e2803c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/avaza/AvazaTasks.vue"
export default component.exports