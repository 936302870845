<template>
    <v-app>
        <Alert />

        <v-data-table
            :headers="headers"
            :items="data"
            :options.sync="options"
            item-key="id"
            class="elevation-1 row-pointer"
            fixed-header
            dense
            :footer-props="{
                'items-per-page-options': [50, 100, 200]
            }"
            :server-items-length="pagination.total"
            :items-per-page="50"
            :loading="loading"
            @click:row="showItem"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Projects</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="options.keyword"
                        label="Search Project ID"
                        class="pt-4"
                    ></v-text-field>
                </v-toolbar>
            </template>

        </v-data-table>
    </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            headers: [
                { text: "Project ID", value: "gte_project_id" },
                { text: "Stage", value: "stage" },
                { text: "Delivery Status", value: "delivery_status" },
                { text: "Type", value: "commercial_type" },
                { text: "Last Synced", value: "last_synced" },
                { text: "Last Actual Dates Update", value: "last_update_actual_dates" },
            ],
            options: {
                keyword: "",
                sortBy: ["gte_project_id"],
                sortDesc: [true]
            }
        };
    },
    created() {
        this.setQuery(this.options);
        this.fetchIndexData();
    },
    beforeDestroy() {
        this.resetState();
    },
    computed: {
        ...mapGetters("ProjectsIndex", [
            "data",
            "pagination",
            "loading"
        ]),
    },
    watch: {
        options: {
            handler(options) {
                this.setQuery(options)
                this.fetchIndexData()
            },
            deep: true
        },
    },
    methods: {
        ...mapActions("ProjectsIndex", [
            "fetchIndexData",
            "setQuery",
            "resetState",
        ]),
        showItem(item) {
            window.location = `projects/${item.gte_project_id}`
        },
    },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
