var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-2", attrs: { loading: _vm.loading, outlined: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "py-0" },
        [
          _c("span", { staticClass: "overline" }, [
            _vm._v("Project Register Details"),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    text: "",
                    href:
                      "https://app.smartsheet.com/sheets/cjMrq22wxV6pHhfX2mJMWJpgW2vFxr86hfc2Vrh1",
                    target: "_blank",
                  },
                },
                [
                  _vm._v("\n                Register\n                "),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-table")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.loading && !_vm.details["Project ID"]
        ? _c("v-card-text", [
            _c("span", { staticClass: "caption" }, [
              _vm._v("The project could not be found in the Project Register."),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading && _vm.details["Project ID"]
        ? _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", {
                                        staticClass: "text-left",
                                        attrs: { colspan: "2" },
                                      }),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [_vm._v("Name:")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.details["Project Name"])
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Type:")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(_vm.details["Type"])),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Client:")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.details["Client"]) +
                                            "\n                                " +
                                            _vm._s(
                                              _vm.details["Client PO Entity"]
                                                ? " (" +
                                                    _vm.details[
                                                      "Client PO Entity"
                                                    ] +
                                                    ")"
                                                : ""
                                            ) +
                                            "\n                            "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("GTE PM:")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(_vm.details["GTE PM"])),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Stage:")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(_vm.details["Stage"])),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Delivery Status:")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.details["Delivery Status"])
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          447629912
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-left",
                                          attrs: { colspan: "2" },
                                        },
                                        [_vm._v("Budget Hrs")]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [_vm._v("Budget at Award")]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.hoursFormat(
                                              _vm.details[
                                                "Total Budget at Award [hrs]"
                                              ]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Requested Change")]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.hoursFormat(
                                              _vm.details[
                                                "Total Requested Change Budget [hrs]"
                                              ]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Approved Change")]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.hoursFormat(
                                              _vm.details[
                                                "Total Approved Change Budget [hrs]"
                                              ]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Total Budget")]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.hoursFormat(
                                              _vm.details["Total Budget [hrs]"]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          970576291
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-left",
                                          attrs: { colspan: "2" },
                                        },
                                        [_vm._v("Budget $")]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [_vm._v("Budget at Award")]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currencyFormat(
                                              _vm.details[
                                                "Total Budget at Award [$]"
                                              ]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Requested Change")]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currencyFormat(
                                              _vm.details[
                                                "Total Requested Change Budget [$]"
                                              ]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Approved Change")]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currencyFormat(
                                              _vm.details[
                                                "Total Approved Change Budget [$]"
                                              ]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Total Budget")]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.currencyFormat(
                                              _vm.details["Total Budget [$]"]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3430810350
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }